import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NgForm} from "@angular/forms";
import {StringUtils} from "../../utils/string.utils";

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  name = 'Changer le mot de passe';
  password = '';
  confirmPassword = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ChangePasswordDialogComponent>) {
  }

  ngOnInit(): void {
  }

  stringLength(string: string) {
    return StringUtils.stringLength(string);
  }

  isPasswordMatch(form: NgForm): boolean {
    if (this.password !== this.confirmPassword) {
      form.controls['confirmPassword'].setErrors({'noMatch': true});
      return false;
    }
    return true;
  }

  changePassword(f: NgForm) {
    if (f.valid) {
      this.dialogRef.close({event: 'close', data: this.password});
    }
  }
}
