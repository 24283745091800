import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from "@angular/router";
import {UserModel} from "../models/user.model";
import {TokenModel} from "../models/token.model";

@Injectable({
  providedIn: 'root'
})
export class AuthRouteGuardService implements CanActivate {

  constructor(private router: Router) {
  }

  /**
   * Check if user has appropriate roles for the current route.
   * @param {ActivatedRouteSnapshot} route
   */
  canActivate(route: ActivatedRouteSnapshot) {
    if (UserModel.getCurrent() && TokenModel.getCurrent().token) {
      if (UserModel.getCurrent().role === UserModel.Roles.admin) {
        return this.router.navigate(['admin', 'clients']);
      }
      if (UserModel.getCurrent().role === UserModel.Roles.client) {
        return this.router.navigate(['dashboard', 'home']);
      }
    }
    return true;

  }
}
