<div style="height: 100px;">
  <div fxLayoutAlign="end center">
    <div fxHide.gt-xs="true" fxFlex.xs="20px"></div>
    <mat-icon class="mobile-menu-icon" fxHide.gt-xs="true" fxFlex="45px" style="height: 45px;"
              [matMenuTriggerFor]="mobileMenu">
      list
    </mat-icon>
    <div fxHide.gt-xs="true" fxFlex.xs></div>
    <div fxFlex="250px">
      <div style="height: 10px"></div>
      <div class="black-hover white-btn" [matMenuTriggerFor]="userMenu" style="height: 60px;"
           fxLayoutAlign="center center">
        <div fxFlex="10px"></div>
        <div fxFlex="50px" class="default-shadow white-bg-color"
             style="height: 46px; width: 46px; border-radius: 23px;"
             fxLayoutAlign="center center">
          <p class="mat-body-2 font-16px no-margin">{{ user.getInitials() }}</p>
        </div>
        <div fxFlex="5px"></div>
        <div fxFlex style="overflow: hidden;">
          <p fxLayoutAlign="center start" class="mat-body-2 no-margin"
             style="height: 25px; overflow: hidden;">{{ user.getFullName() }}</p>
          <p fxLayoutAlign="center start" class="mat-caption no-margin">{{ user.email }}</p>
        </div>
        <div fxFlex="10px"></div>
      </div>
    </div>
    <div fxFlex="10px"></div>
  </div>
</div>

<mat-menu #userMenu="matMenu">
  <div style="width: 250px;">
    <button mat-menu-item (click)="logout()">
      <mat-icon>logout</mat-icon>
      Déconnexion
    </button>
  </div>
</mat-menu>

<mat-menu #mobileMenu="matMenu">
  <div style="width: 300px;">
    <button mat-menu-item routerLink="/admin/clients" routerLinkActive="mobile-active-link">
      <mat-icon>people</mat-icon>
      Clients
    </button>
    <button mat-menu-item routerLink="/admin/campaigns" routerLinkActive="mobile-active-link">
      <mat-icon>data_usage</mat-icon>
      Campagnes
    </button>
  </div>
</mat-menu>
