import {CampaignModel} from "./campaign.model";
import {DateUtils} from "../../utils/date-utlis.model";

export class ReportModel {

  id: number;
  date: Date;
  file: string;
  campaign: CampaignModel;
  is_consulted: boolean;
  isChecked: boolean;

  static generateModel(json: any): ReportModel {

    const report = new ReportModel();
    report.id = json.id;
    report.date = DateUtils.date(json.date);
    report.file = json.file;
    report.campaign = CampaignModel.generateModel(json.campaign);
    report.is_consulted = json.is_consulted;
    report.isChecked = false;

    return report;
  }

  static generateModels(jsonList: any): ReportModel[] {

    const list = [];

    for (const json of jsonList) {
      list.push(ReportModel.generateModel(json));
    }
    return list;

  }
}
