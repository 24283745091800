import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CampaignModel} from "../../models/campaign/campaign.model";
import {CampaignService} from "../../services/campaign.service";

@Component({
  selector: 'app-dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardSidebarComponent implements OnInit {

  campaigns: CampaignModel[] = [];
  selected: CampaignModel = null;

  constructor(private campaignSv: CampaignService) {
  }

  ngOnInit(): void {
    this.campaignSv.clientCampaigns.subscribe((campaigns: CampaignModel []) => {
      if (campaigns.length > 0) {
        this.campaigns = campaigns;
        this.campaignSv.currentCampaign.next(this.campaigns[0]);
        this.selected = this.campaigns[0];
      }
    });
  }

  setCampaign(campaign: CampaignModel) {
    this.campaignSv.currentCampaign.next(campaign);
  }

}
