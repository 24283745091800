import {Injectable} from '@angular/core';
import {AuthService} from "./auth.service";
import {map, Observable} from "rxjs";
import {ReportModel} from "../models/campaign/report.model";
import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private authSv: AuthService) {
  }

  fetchCampaignsReports(campaignId: number): Observable<ReportModel[]> {
    let url = '/report?action=get_by_campaign&campaign_id=' + campaignId;

    return this.authSv.get(url).pipe(map((response) => {

      return ReportModel.generateModels(response.data);

    }));
  }

  fetchCampaignsReportsByDate(campaignId: number, date: string): Observable<ReportModel[]> {
    let url = '/report?action=get_by_campaign_and_date&campaign_id=' + campaignId;
    url += '&date=' + date;

    return this.authSv.get(url).pipe(map((response) => {

      return ReportModel.generateModels(response.data);

    }));
  }

  downloadReportById(reportId: number): Observable<any> {
    const url = '/report/download/' + reportId;

    return this.authSv.getPDF(url).pipe(map((response) => {

      return window.URL.createObjectURL(response);

    }));
  }

  sendReportsByEmail(reports: ReportModel[]): Observable<any> {
    const url = '/report';
    let body = {};

    body['action'] = 'send_by_mail';
    body['reports'] = _.map(reports, 'id');

    return this.authSv.post(url, body, true).pipe(map((response) => {

      return response;

    }))
  }
}
