<div fxLayoutAlign="start center">
  <div fxFlex="100">
    <div fxLayoutAlign="end center" fxLayoutAlign.xs="center start">
      <button fxFlex="140px" mat-raised-button style="height: 40px;"
              [disabled]="!isDataChanged || isDisable" (click)="saveClient()" color="primary">
        Sauvegarder
      </button>
      <div fxFlex="20px"></div>
      <button fxFlex="140px" mat-raised-button style="height: 40px;"
              [disabled]="!isDataChanged || isDisable" (click)="loadClient()" color="warn">
        Annuler
      </button>
    </div>
    <div style="height:10px"></div>
    <div class="box no-scroll-bar" style="overflow-x: scroll; overflow-y: hidden;">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 pollster-table">

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef class="light-black-bg-color white-text font-14px"> Identifiant</th>
          <td mat-cell class="font-16px"
              *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- Code Column -->
        <ng-container matColumnDef="code">
          <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef> Code
          </th>
          <td mat-cell class="font-16px"
              *matCellDef="let element"> {{element.code}} </td>
        </ng-container>

        <!-- Start date Column -->
        <ng-container matColumnDef="start_date">
          <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef> Date de début
          </th>
          <td mat-cell class="font-16px"
              *matCellDef="let element"> {{element.start_date | date: 'MM/YYYY'}} </td>
        </ng-container>

        <!-- Paid hours Column -->
        <ng-container matColumnDef="paid_hours">
          <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef> Heures payées
          </th>
          <td mat-cell class="font-16px"
              *matCellDef=" let element"> {{element.paid_hours}} </td>
        </ng-container>

        <!-- Injected contacts Column -->
        <ng-container matColumnDef="injected_contacts">
          <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef> Contacts injectés
          </th>
          <td mat-cell class="font-16px"
              *matCellDef=" let element"> {{element.injected_contacts}} </td>
        </ng-container>

        <!-- Remove campaign Column -->
        <ng-container matColumnDef="remove_campaign">
          <th mat-header-cell class="light-black-bg-color" *matHeaderCellDef>
            <button mat-mini-fab style="height: 30px; width: 30px;" color="primary"
                    *ngIf="!isLoading" [disabled]="isDisable" (click)="addCampaigns()">
              <mat-icon style="margin-top: -10px;">add</mat-icon>
            </button>
            <div *ngIf="isLoading"
                 style="display: flex; justify-content: center; align-items: center;">
              <mat-progress-spinner
                diameter="30"
                color="primary"
                mode="indeterminate">
              </mat-progress-spinner>
            </div>
          </th>
          <td mat-cell *matCellDef=" let element">
            <button mat-mini-fab style="height: 30px; width: 30px;" color="warn" (click)="removeCampaign(element)"
                    [disabled]="isDisable">
              <mat-icon style="margin-top: -10px;">remove</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
