import {Component, Input} from '@angular/core';
import {AdminClientService} from "../../../services/admin/admin-client.service";
import {CampaignModel} from "../../../models/campaign/campaign.model";
import {MatTableDataSource} from "@angular/material/table";
import * as _ from "lodash";
import {UserModel} from 'src/app/models/user.model';
import {AdminCampaignService} from "../../../services/admin/admin-campaign.service";
import {MatDialog} from "@angular/material/dialog";
import {TableDialogComponent} from "../../../dialog/table/table-dialog.component";

@Component({
  selector: 'app-admin-client-campaigns',
  templateUrl: './admin-client-campaigns.component.html',
  styleUrls: ['./admin-client-campaigns.component.scss']
})
export class AdminClientCampaignsComponent {

  isDisable = false;
  isDataChanged = false;
  isLoading = false;
  displayedColumns: string[] = ['id', 'code', 'start_date', 'paid_hours', 'injected_contacts', 'remove_campaign'];
  dataSource = new MatTableDataSource<CampaignModel>();

  @Input() set datasource(value: CampaignModel[]) {
    this.dataSource.data = value;
  }

  @Input() client: UserModel = null;


  constructor(private adminUserSv: AdminClientService, private adminCampSv: AdminCampaignService, private dialog: MatDialog) {
  }

  loadClient() {

    this.isDisable = true
    this.adminUserSv.fetchClient(this.client.id).subscribe((client: UserModel) => {
      this.dataSource.data = client.campaigns;
      this.isDataChanged = false;
      this.isDisable = false;
    });

  }

  saveClient() {

    this.isDisable = true;
    this.adminUserSv.updateClientCampaigns(this.dataSource.data, this.client.id).subscribe(() => {
      this.loadClient();
    });

  }

  removeCampaign(campaign: CampaignModel) {

    _.remove(this.dataSource.data, (c: CampaignModel) => {
      return c.id === campaign.id;
    });

    this.isDataChanged = true;
    this.dataSource._updateChangeSubscription();

  }

  addCampaigns() {
    this.isLoading = true;
    this.adminCampSv.fetchAllCampaigns().subscribe((campaigns: CampaignModel[]) => {
      this.isLoading = false;
      this.dialog.open(TableDialogComponent, {
        minWidth: '20%',
        data: {
          name: 'Choisir des campagnes',
          displayedColumns: ['id', 'code', 'start_date', 'paid_hours', 'injected_contacts', 'button'],
          displayedLabels: ['Identifiant', 'Code', 'Date de début', 'Heures payées', 'Heures payées', 'Contacts injectés', ''],
          dataSource: campaigns
        },
        autoFocus: false
      }).afterClosed().subscribe(event => {
        if (event) {
          if (event.data) {
            this.dataSource.data.push(event.data);
            this.dataSource._updateChangeSubscription();
            this.saveClient();
          }
        }
      })
    });
  }
}
