import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {SignInComponent} from '../auth/sign-in/sign-in.component';
import {DashboardRootComponent} from '../dashboard/dashboard-root.component';
import {DashboardFilesComponent} from "../dashboard/dashboard-files/dashboard-files.component";
import {DashboardHomeComponent} from "../dashboard/dashboard-home/dashboard-home.component";
import {AdminRootComponent} from "../admin/admin-root.component";
import {RouteGuardService} from './route-guard.service';
import {UserModel} from '../models/user.model';
import {AuthRouteGuardService} from "./auth-route-guard.service";
import {AdminClientsComponent} from "../admin/admin-clients/admin-clients.component";
import {AdminCampaignsComponent} from "../admin/admin-campaigns/admin-campaigns.component";
import {AdminClientDetailComponent} from "../admin/admin-clients/admin-client-detail/admin-client-detail.component";
import {
  AdminCampaignDetailComponent
} from "../admin/admin-campaigns/admin-campaign-detail/admin-campaign-detail.component";

const appRoutes: Routes = [
  {path: '', redirectTo: 'signin', pathMatch: 'full'},
  {path: 'signin', component: SignInComponent, canActivate: [AuthRouteGuardService]},
  {
    path: 'dashboard',
    component: DashboardRootComponent,
    canActivate: [RouteGuardService],
    data: {routeAllowedRole: UserModel.Roles.client},
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'home'},
      {path: 'home', component: DashboardHomeComponent},
      {path: 'files', component: DashboardFilesComponent}
    ]
  },
  {
    path: 'admin',
    component: AdminRootComponent,
    canActivate: [RouteGuardService],
    data: {routeAllowedRole: UserModel.Roles.admin},
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'clients'},
      {path: 'clients', component: AdminClientsComponent},
      {path: 'clients/:clientId/details', component: AdminClientDetailComponent},
      {path: 'campaigns', component: AdminCampaignsComponent},
      {path: 'campaigns/:campaignId/details', component: AdminCampaignDetailComponent}
    ]
  },
  {path: '**', redirectTo: 'signin'}
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
