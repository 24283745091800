import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {UserModel} from "../models/user.model";
import {catchError, map, Observable, throwError} from "rxjs";
import {TokenModel} from "../models/token.model";
import {environment} from "../../environments/environment";
import {ErrorModel} from "../models/error.model";
import {Router} from '@angular/router';
import {MatDialog} from "@angular/material/dialog";
import {ErrorDialogComponent} from "../dialog/error/error-dialog.component";
import {SuccessDialogComponent} from "../dialog/success/success-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = environment.pollsterApi;

  constructor(private http: HttpClient, private router: Router, private dialog: MatDialog) {
  }

  signIn(email: string, password: string): Observable<{ user: UserModel, token: TokenModel }> {

    const body = {
      email,
      password,
      action: 'login'
    };

    return this.post('/auth', body).pipe(map((response) => {

      const currentToken = response.data.token;
      delete response.data.token;

      const user = UserModel.generateModel(response.data);
      const token = TokenModel.generateModel({token: currentToken});
      UserModel.setCurrent(user);
      TokenModel.setCurrent(token);

      return {user, token};

    }));

  }

  getHeadersAccessToken() {

    const headers = {'Content-Type': 'application/json'};
    const currentToken = TokenModel.getCurrent();
    if (currentToken) {
      headers['x-auth'] = currentToken.token;
    }
    return headers;
  }


  post(url: string, body: any, isSuccessModal = false): Observable<{ data: any }> {

    const headers = this.getHeadersAccessToken();

    let completeUrl = this.baseUrl + url;

    return this.http.post(completeUrl,
      body,
      {
        observe: 'body',
        headers: headers
      }).pipe(map((response) => {

      if (isSuccessModal) {
        this.dialog.open(SuccessDialogComponent, {
          minWidth: '20%',
          data: {
            msg: response["msg"]
          },
          autoFocus: false
        })
      }

      return <{ data: any }>response;

    }), catchError(err => {

      return throwError(this.checkErrorStatus(err));

    }));

  }

  postPDF(url: string, form: FormData): Observable<{ data: any }> {


    const token = this.getHeadersAccessToken()['x-auth'];
    const headers = new HttpHeaders().set('x-auth', token);

    let completeUrl = this.baseUrl + url;

    return this.http.post(completeUrl,
      form,
      {
        observe: 'body',
        headers: headers,
      }).pipe(map((response) => {

        this.dialog.open(SuccessDialogComponent, {
          minWidth: '20%',
          data: {
            msg: response["msg"]
          },
          autoFocus: false
        })

      return <{ data: any }>response;

    }), catchError(err => {

      return throwError(this.checkErrorStatus(err));

    }));

  }

  patch(url: string, body: any, isSuccessModal = false): Observable<{ data: any }> {

    const headers = this.getHeadersAccessToken();

    let completeUrl = this.baseUrl + url;

    return this.http.patch(completeUrl,
      body,
      {
        observe: 'body',
        headers: headers
      }).pipe(map((response) => {

      if (isSuccessModal) {
        this.dialog.open(SuccessDialogComponent, {
          minWidth: '20%',
          data: {
            msg: response["msg"]
          },
          autoFocus: false
        })
      }

      return <{ data: any }>response;

    }), catchError(err => {

      return throwError(this.checkErrorStatus(err));

    }));

  }

  get(url: string): Observable<{ data: any }> {

    const headers = this.getHeadersAccessToken();

    let completeUrl = this.baseUrl + url;

    return this.http.get(completeUrl,
      {
        observe: 'body',
        headers: headers
      }).pipe(map((response) => {
      return <{ data: any }>response;

    }), catchError(err => {

      return throwError(this.checkErrorStatus(err));

    }));

  }

  getPDF(url: string): Observable<Blob> {

    const headers = this.getHeadersAccessToken();

    let completeUrl = this.baseUrl + url;

    return this.http.get(completeUrl,
      {
        observe: 'body',
        headers: headers,
        responseType: 'blob'
      }).pipe(map((response) => {

      return response;

    }), catchError(err => {

      return throwError(this.checkErrorStatus(err));

    }));
  }

  delete(url: string): Observable<{ data: any }> {

    const headers = this.getHeadersAccessToken();

    let completeUrl = this.baseUrl + url;

    return this.http.delete(completeUrl,
      {
        observe: 'body',
        headers: headers
      }).pipe(map((response) => {

      return <{ data: any }>response;

    }), catchError(err => {

      return throwError(this.checkErrorStatus(err));

    }));

  }

  openDialogError(name, msg) {
    return this.dialog.open(ErrorDialogComponent, {
      minWidth: '20%',
      data: {
        name: name,
        msg: msg,
      },
      autoFocus: false
    })
  }

  checkErrorStatus(err) {
    const error = ErrorModel.catchError(err);

    if (!navigator.onLine) {
      return this.openDialogError('Impossible de se connecter à Internet', 'Impossible de se connecter à Internet... Veuillez vérifier votre connexion !');
    }

    switch (err.status) {
      case 401:
        UserModel.removeCurrent();
        TokenModel.removeCurrent();
        return this.openDialogError('Connexion expirée', 'Votre connexion a expiré').afterClosed().subscribe(() => {
          return this.router.navigate(['signin']);
        });

      case 500:
        UserModel.removeCurrent();
        TokenModel.removeCurrent();
        return this.openDialogError('Erreur serveur', 'Erreur de serveur interne').afterClosed().subscribe(() => {
          return this.router.navigate(['signin']);
        });

      case 404:
        return this.openDialogError('Ressource introuvable', 'Impossible de trouver la ressource demandée');

      default:
        return this.dialog.open(ErrorDialogComponent, {
          minWidth: '20%',
          data: {
            msg: error.msg,
          },
          autoFocus: false
        });
    }
  }
}
