import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  message = 'Êtes-vous sûr de vouloir effectuer cette action ?';
  warningMessage = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string, warningMessage?: string },
              private dialogRef: MatDialogRef<ConfirmDialogComponent>) {
  }

  ngOnInit(): void {

    if (this.data.message) {
      this.message = this.data.message;
    }

    if (this.data.warningMessage) {
      this.warningMessage = this.data.warningMessage;
    }

  }

  cancel() {

    this.dialogRef.close({event: 'close', data: false});

  }

  confirm() {

    this.dialogRef.close({event: 'close', data: true});

  }

}
