<div fxLayoutAlign="start center">
  <div fxFlex="100">
    <div fxLayoutAlign="end center" fxLayoutAlign.xs="center start">
      <button fxFlex="140px" mat-raised-button style="height: 40px;" color="primary"
              [disabled]="!isDataChanged || isDisabled" (click)="deleteReports();">
        Sauvegarder
      </button>
      <div fxFlex="20px"></div>
      <button fxFlex="140px" mat-raised-button style="height: 40px;" color="warn"
              [disabled]="!isDataChanged || isDisabled" (click)="loadReports()">
        Annuler
      </button>
    </div>
    <div style="height:10px"></div>
    <div class="box no-scroll-bar" style="overflow-x: scroll; overflow-y: hidden;">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 pollster-table">

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef class="light-black-bg-color white-text font-14px"> Identifiant</th>
          <td mat-cell class="font-16px"
              *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- File Column -->
        <ng-container matColumnDef="file">
          <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef> Document
          </th>
          <td mat-cell class="font-16px"
              *matCellDef="let element">
            <button (click)="openPDF(element)" [disabled]="isDisabled"
                    mat-raised-button style="width: 60%;">
              Ouvrir
            </button>
          </td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef> Date
          </th>
          <td mat-cell class="font-16px"
              *matCellDef="let element"> {{element.date | date:'dd/MM/YYYY'}} </td>
        </ng-container>

        <!-- Is Consulted Column -->
        <ng-container matColumnDef="is_consulted">
          <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef> A été ouvert
          </th>
          <td mat-cell class="font-16px"
              *matCellDef=" let element">
            <mat-icon *ngIf="element.is_consulted" style="color: green;">check</mat-icon>
            <mat-icon *ngIf="!element.is_consulted" style="color: red;">close</mat-icon>
          </td>
        </ng-container>

        <!-- Remove Report Column -->
        <ng-container matColumnDef="remove_report">
          <th mat-header-cell class="light-black-bg-color" *matHeaderCellDef>
            <button mat-mini-fab fxHide.lt-md="true" style="height: 30px; width: 30px;" color="primary"
                    [disabled]="isDisabled || isFinished"
                    (click)="addReport()"
                    *ngIf="!isLoading">
              <mat-icon style="margin-top: -10px;">add</mat-icon>
            </button>
            <div *ngIf="isLoading"
                 style="display: flex; justify-content: center; align-items: center;">
              <mat-progress-spinner
                diameter="30"
                color="primary"
                mode="indeterminate">
              </mat-progress-spinner>
            </div>
          </th>
          <td mat-cell *matCellDef=" let element">
            <button mat-mini-fab style="height: 30px; width: 30px;" color="warn"
                    (click)="removeReport(element)" [disabled]="isDisabled ||isFinished">
              <mat-icon style="margin-top: -10px;">remove</mat-icon>
            </button>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
<div *ngIf="isLoading"
     style="display: flex; justify-content: center; align-items: center; height: 100%;">
  <mat-progress-spinner
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
</div>
