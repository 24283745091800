import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AdminClientService} from "../../services/admin/admin-client.service";
import {UserModel} from "../../models/user.model";
import {MatTableDataSource} from "@angular/material/table";
import {Router} from '@angular/router';
import {MatPaginator} from "@angular/material/paginator";
import {MatDialog} from "@angular/material/dialog";
import {AddClientDialogComponent} from "../../dialog/add-client/add-client-dialog.component";

@Component({
  selector: 'app-admin-clients',
  templateUrl: './admin-clients.component.html',
  styleUrls: ['./admin-clients.component.scss']
})
export class AdminClientsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['id', 'email', 'society', 'address', 'campaigns', 'alternative_email'];
  dataSource = new MatTableDataSource<UserModel>();
  isLoading = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private adminUserSv: AdminClientService, private router: Router, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.loadClient()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  loadClient() {
    this.isLoading = true;
    this.adminUserSv.fetchAllClients().subscribe((clients: UserModel []) => {
      this.dataSource.data = clients;
      this.isLoading = false;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onClientClicked(client: UserModel) {
    this.router.navigate(['admin', 'clients', client.id, 'details']);
  }

  createClient() {
    this.dialog.open(AddClientDialogComponent, {
      minWidth: '20%',
      autoFocus: false
    }).afterClosed().subscribe(event => {
      if (event) {
        if (event.data) {
          this.loadClient();
        }
      }
    });
  }
}
