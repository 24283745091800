import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {StatisticModel} from "../../models/campaign/statistic.model";
import {CampaignModel} from "../../models/campaign/campaign.model";
import * as _ from "lodash";

@Component({
  selector: 'app-statistics-container',
  templateUrl: './statistics-container.component.html',
  styleUrls: ['./statistics-container.component.scss']
})
export class StatisticsContainerComponent implements OnInit {

  displayedColumns: string[] = ['week', 'date', 'completedHours', 'identifiedProjects', 'arguedContacts', 'deals', 'dealTracking', 'details', 'bonus', 'totals'];
  @Input() dataSource = new MatTableDataSource<StatisticModel>();
  @Input() campaign: CampaignModel = null;
  @Input() isFilter = true;

  totals = {
    treatedCalls: 0,
    numberOfHours: 0,
    detectedProject: 0,
    arguContact: 0,
    trxTot: 0,
    info: 0,
    bonus: 0,
    totals: 0
  };

  averages = {
    contactsPerHour: 0,
    detectedProjectPerHour: 0,
    fileArgu: 0
  }

  ngOnInit(): void {

    this.totals = _.mapValues(this.totals, () => 0);
    this.averages = _.mapValues(this.averages, () => 0);
    this.fetchStatisticsForCampaign();

  }

  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

  }

  fetchStatisticsForCampaign() {

    this.dataSource.data.forEach((ht) => {
      this.totals.numberOfHours += ht.number_of_hours;
      this.totals.detectedProject += ht.detected_project;
      this.totals.arguContact += ht.argu_contact;
      this.totals.trxTot += ht.trx;
      this.totals.trxTot += ht.trx_followup;
      this.totals.info += ht.info;
      this.totals.bonus += ht.bonus;
      this.totals.totals += ht.total;
      this.totals.treatedCalls += ht.treated_calls;
      this.averages.detectedProjectPerHour += (ht.detected_project / ht.number_of_hours);
    });
    this.averageCalc();

  }

  averageCalc() {

    this.averages.contactsPerHour = Number((this.totals.arguContact / this.totals.numberOfHours).toFixed(2));
    this.averages.detectedProjectPerHour = Number((this.totals.detectedProject / this.totals.numberOfHours).toFixed(2));
    this.averages.fileArgu = Number(((this.totals.arguContact / this.campaign.injected_contacts) * 100).toFixed(2));

  }
}
