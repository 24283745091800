import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {CampaignModel} from 'src/app/models/campaign/campaign.model';
import {CampaignService} from "../../services/campaign.service";
import {StatisticModel} from "../../models/campaign/statistic.model";

@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.scss']
})
export class DashboardHomeComponent implements OnInit {

  dataSource = new MatTableDataSource<StatisticModel>();
  currentCampaign: CampaignModel = null;
  isLoading = true;

  constructor(private campaignSv: CampaignService) {
  }

  ngOnInit(): void {

    this.campaignSv.currentCampaign.subscribe((currentCampaign: CampaignModel) => {
      if (currentCampaign) {
        if (this.currentCampaign !== currentCampaign) {
          this.isLoading = true;
          this.currentCampaign = currentCampaign;
          this.fetchStatisticsForCampaign(this.currentCampaign.id);
        }
      } else {
        this.isLoading = false;
      }
    });

  }

  fetchStatisticsForCampaign(campaignId: number) {

    this.campaignSv.fetchStatisticsByCampaign(campaignId).subscribe((statistics: StatisticModel[]) => {

      this.dataSource.data = statistics;
      this.isLoading = false;

    });

  }
}
