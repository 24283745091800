<h1 mat-dialog-title fxLayoutAlign="center start">{{successName}}</h1>

<div mat-dialog-content fxLayoutAlign="center center">
  <div style="height:20px" ngStyle.lt-md="height: 10px;"></div>
  <div fxFlex="90" fxLayoutAlign="center start" *ngIf="!isLoading">
    <div style="width: 100%;">
      <div style="height:20px" ngStyle.lt-md="height: 10px;"></div>
      <div>
        <mat-form-field appearance="standard" fxFlex ngStyle.xs="width: 95%;">
          <mat-label>Date</mat-label>
          <input matInput [matDatepicker]="picker" required
                 [min]="minDate"
                 [max]="maxDate"
                 [ngModel]="date" (ngModelChange)="date = $event">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div style="height: 10px;"></div>
      <div>
        <ngx-file-drop dropZoneLabel="Déposer une fichier ici" (onFileDrop)="dropped($event)"
                       accept=".pdf" [multiple]="false" #fileSelector>
        </ngx-file-drop>
        <div style="height: 10px;"></div>
        <div fxLayoutAlign="center start">
          <button mat-raised-button color="primary" (click)="fileSelector.openFileSelector()">
            Sélectionner un fichier
          </button>
        </div>
      </div>
      <div *ngIf="file">
        <div class="font-16px">
          Document à importer :
        </div>
        <div fxLayoutAlign="start center">
          <div class="font-16px">
            {{file.relativePath}}
          </div>
          <div fxFlex="10px"></div>
          <button mat-mini-fab style="height: 25px; width: 25px;" color="warn"
                  (click)="removeFile()">
            <mat-icon style="margin-top: -15px;">remove</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading"
       style="display: flex; justify-content: center; align-items: center; height: 100%;">
    <mat-progress-spinner
      color="primary"
      mode="indeterminate">
    </mat-progress-spinner>
  </div>
  <div style="height:20px" ngStyle.lt-md="height: 10px;"></div>
</div>
<div style="height: 10px;"></div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-raised-button color="accent" mat-dialog-close="true" [disabled]="isLoading">Fermer</button>
  <button mat-raised-button color="primary" (click)="saveReports()" [disabled]="!file || isLoading">Sauvegarder</button>
</div>
<div style="height: 5px;"></div>
