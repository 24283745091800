import {Component, OnInit} from '@angular/core';
import {CampaignModel} from "../models/campaign/campaign.model";
import {DateUtils} from "../utils/date-utlis.model";
import {CampaignService} from "../services/campaign.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-root.component.html',
  styleUrls: ['./dashboard-root.component.scss']
})
export class DashboardRootComponent implements OnInit {

  constructor(private campaignSv: CampaignService) {
  }

  ngOnInit(): void {
    this.campaignSv.fetchUserCampaigns().subscribe((campaigns: CampaignModel []) => {
      const camps = campaigns.sort((a, b) => {
        return DateUtils.getTime(b.start_date) - DateUtils.getTime(a.start_date);
      });

      this.campaignSv.currentCampaign.next(camps[0]);
      this.campaignSv.clientCampaigns.next(camps);
    });
  }

}
