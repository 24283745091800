export class TokenModel {

  private static currentToken: TokenModel;
  private static storageKey = 'pollsterToken';

  public token: string;

  static generateModel(json: any): TokenModel {

    const token = new TokenModel();
    token.token = json.token;

    return token;
  }

  static getCurrent(): TokenModel | null {

    if (this.currentToken) {
      return this.currentToken;
    }

    const tokenJson = localStorage.getItem(this.storageKey);
    if (tokenJson) {
      this.currentToken = TokenModel.generateModel(JSON.parse(tokenJson));
      return this.currentToken;
    }

    return null;
  }

  static setCurrent(token: TokenModel) {

    this.currentToken = token;
    if (token) {
      localStorage.setItem(this.storageKey, JSON.stringify(token));
    } else {
      this.removeCurrent();
    }
  }

  static removeCurrent() {
    this.currentToken = null;
    localStorage.removeItem(this.storageKey);
  }
}
