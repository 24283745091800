import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss']
})
export class TableDialogComponent implements OnInit, AfterViewInit {

  name = 'Table';
  displayedColumns: string[] = [];
  displayedLabels: string[] = [];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private dialogRef: MatDialogRef<TableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string, displayedColumns: string[], displayedLabels: string[], dataSource: any[] }) {
  }

  ngOnInit(): void {
    if (this.data.name) {
      this.name = this.data.name;
    }
    this.displayedColumns = this.data.displayedColumns;
    this.displayedLabels = this.data.displayedLabels;
    this.dataSource.data = this.data.dataSource;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onAddClicked(element: any) {
    this.dialogRef.close({event: 'close', data: element});
  }
}
