import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {CampaignModel} from 'src/app/models/campaign/campaign.model';
import {AdminCampaignService} from "../../services/admin/admin-campaign.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {Router} from "@angular/router";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../dialog/confirm/confirm-dialog.component";

@Component({
  selector: 'app-admin-campaigns',
  templateUrl: './admin-campaigns.component.html',
  styleUrls: ['./admin-campaigns.component.scss']
})
export class AdminCampaignsComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['id', 'code', 'start_date', 'paid_hours', 'injected_contacts', 'is_finished'];
  dataSource = new MatTableDataSource<CampaignModel>();
  isLoading = true;
  isLoadingStats = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private adminCampSv: AdminCampaignService, private router: Router, private dialog: MatDialog) {
  }

  ngOnInit(): void {

    this.loadCampaigns().then();

  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }

  loadCampaigns() {

    return new Promise<void>((resolve, reject) => {
      this.adminCampSv.fetchAllCampaigns().subscribe((campaigns: CampaignModel[]) => {
        this.dataSource.data = campaigns;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
        resolve();
      }, (err) => {
        reject(new Error(err));
      })
    }).catch((err) => {
      console.error(err);
    });

  }

  applyFilter(event: Event) {

    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

  }

  onCampaignClicked(campaign: CampaignModel) {

    this.router.navigate(['admin', 'campaigns', campaign.id, 'details']);

  }

  updateCampaigns() {

    this.isLoading = true;
    this.dataSource = new MatTableDataSource<CampaignModel>();
    this.adminCampSv.updateCampaigns().subscribe(() => {
      this.loadCampaigns().then(() => {
        this.dataSource.paginator = this.paginator;
      });
    });

  }

  updateCampaignsStatisticsLastWeek() {

    this.dialog.open(ConfirmDialogComponent, {
      minWidth: '20%',
      data: {
        message: 'Êtes-vous sûr de vouloir mettre à jour les statiques de la semaine dernière pour les campagnes ?',
        warningMessage: 'Cette action peut prendre quelque temps !'
      },
      autoFocus: false
    }).afterClosed().subscribe(event => {
      if (event) {
        if (event.data) {
          this.isLoadingStats = true;
          this.adminCampSv.updateCampaignsStatisticsLastWeek().subscribe(() => {
            this.isLoadingStats = false;
          });
        }
      }
    });

  }

}
