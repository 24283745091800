<div style="height: 100%;" class="light-orange-bg-color">
  <form #f="ngForm" style="width: 100%; height: 100%;" fxLayoutAlign="center center">
    <div fxFlex.xs="95" fxFlex.gt-xs="540px">
      <div style="height: 20px"></div>
      <mat-card>
        <mat-card-title fxLayoutAlign="center start">Se connecter</mat-card-title>
        <div style="height: 10px;"></div>

        <div fxLayoutAlign="center start">
          <div style="height: 10px;"></div>
          <img mat-card-image src="./assets/pollster-big.png"
               alt="Logo Pollster"
               style="width: 100%; height: 120px; object-fit: contain;">
        </div>
        <div style="height: 20px;"></div>
        <mat-card-content fxLayoutAlign="center start">
          <div fxFlex="80">
            <mat-form-field appearance="outline" style="width:100%">
              <mat-label>Adresse électronique</mat-label>
              <input ngModel matInput placeholder="email@email.com" name="email"
                     pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" type="email" required>
            </mat-form-field>
            <div style="height: 20px;"></div>
            <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label>Mot de passe</mat-label>
              <input ngModel matInput placeholder="Mot de passe" name="password" type="password" required>
            </mat-form-field>
          </div>
        </mat-card-content>
        <div style="height: 30px"></div>
        <mat-card-actions fxLayoutAlign="center start">
          <div fxFlex="80" fxLayoutAlign="center center">
            <div>
              <button mat-raised-button style="width: 140px; height: 40px;" [disabled]="!f.valid || isLoading"
                      fxLayoutAlign="center center" color="primary"
                      (click)="onSignInClicked(f)">
                Se connecter
              </button>
              <div style="height: 5px;"></div>
              <div *ngIf="isLoading" style="width: 140px;">
                <mat-progress-bar
                  mode="indeterminate">
                </mat-progress-bar>
              </div>
            </div>
          </div>
        </mat-card-actions>
        <div style="height: 20px"></div>
      </mat-card>
    </div>
  </form>
</div>
