<div style="height: 100%; overflow: scroll" fxLayoutAlign="center start" class="scroll-bar-orange-thumb"
     *ngIf="!isLoading && reports.length > 0">
  <div fxFlex="98" style="height: 100%;">
    <div style="height:10px"></div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center start"
         style="height: calc(100% - 30px);" ngStyle.lt-md="height: auto;">
      <div class="box scroll-bar-orange-thumb" fxFlex="39" fxFlex.lt-md="35" ngStyle.lt-md="width: 100%;"
           style="height: 100%; overflow-y: scroll; overflow-x: hidden;">
        <div fxFlex="20px" fxFlex.lt-md="10px"></div>
        <div fxFlex>
          <div style="height:20px"></div>
          <div fxLayout="row wrap">
            <mat-form-field appearance="fill">
              <mat-label>Choisir la date</mat-label>
              <mat-select (selectionChange)="fetchReportsByDate(selected)" [(ngModel)]="selected">
                <mat-option *ngFor="let date of reportDates" [value]="date.value">
                  {{date.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div fxFlex="10px"></div>
            <button fxFlex="120px" fxHide.lt-md="true" (click)="download()" mat-raised-button style="height: 40px;"
                    [disabled]="!reports.find(isFileChecked)"
                    fxLayoutAlign="center center" color="primary">
              Télécharger
            </button>
            <div fxFlex="10px"></div>
            <button fxFlex="120px" (click)="sendByEmail()" mat-raised-button style="height: 40px;"
                    [disabled]="!reports.find(isFileChecked)"
                    fxLayoutAlign="center center" color="accent">
              Envoyer
            </button>
          </div>
          <div style="height: 10px;"></div>
          <div *ngIf="!isLoadingReports">
            <h3>Sélectionnez vos fichiers :</h3>
            <div>
              <mat-checkbox fxFlex fxLayoutAlign="start center" (change)="checkAll()" [checked]="selectAllCheckbox">Tout
                sélectionner
              </mat-checkbox>
            </div>
            <div style="height: 10px;"></div>
            <div *ngFor="let report of reports">
              <div fxLayout="row wrap">
                <mat-checkbox fxFlex fxLayoutAlign="start center" (change)="checkFile(report)"
                              [checked]="report.isChecked">{{report.date | date: 'dd/MM/YYYY'}} {{report.campaign.code}}</mat-checkbox>
                <div fxFlex="140px" fxLayoutAlign="center center">
                  <button (click)="preview(report)" [disabled]="report === reportPreviewed"
                          mat-raised-button style="height: 25px;"
                          fxLayoutAlign="center center">
                    Prévisualisation
                  </button>
                </div>
              </div>
              <div style="height: 20px;"></div>
            </div>
          </div>
          <div *ngIf="isLoadingReports"
               style="display: flex; justify-content: center; align-items: center; height: 80%;">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate">
            </mat-progress-spinner>
          </div>
          <div style="height:10px"></div>
        </div>
        <div fxFlex="20px" fxFlex.lt-md="10px"></div>
      </div>
      <div fxFlex="2" fxFlex.lt-md="20px"></div>
      <div fxFlex="59" fxFlex.lt-md="300px" class="box" style="position: relative; height: 100%;"
           ngStyle.lt-md="width: 100%;">
        <div *ngIf="!reportUrl" class="font-20px no-margin " fxLayoutAlign="center center"
             style="height: 100%;">
          Pas de pdf en prévisualisation.
        </div>
        <pdf-viewer
          style="border-radius: 6px;"
          *ngIf="reportUrl"
          [src]="reportUrl"
          [rotation]="0"
          [zoom]="1"
          [show-all]="true"
          [autoresize]="true"
          [original-size]='false'
          [fit-to-page]='false'
          [render-text]='true'
        ></pdf-viewer>
      </div>
    </div>
    <div style="height:20px"></div>
  </div>
</div>

<div style="height: 100%;"
     *ngIf="!isLoading && reports.length === 0">
  <div fxFlex="98" fxLayoutAlign="center center">
    <div class="font-20px no-margin text-center" fxLayoutAlign="center center">
      Vous n'avez pas de document disponible !
    </div>
  </div>
</div>

<div *ngIf="isLoading"
     style="display: flex; justify-content: center; align-items: center; height: 100%;">
  <mat-progress-spinner
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
</div>
