<div style="height: 100%; overflow: scroll" fxLayoutAlign="center start" class="scroll-bar-orange-thumb">
  <div fxFlex="98">
    <div style="height:10px"></div>
    <div class="box" style="height: 50px;" fxLayoutAlign="center center">
      <div fxLayoutAlign="center center" fxFlex>
        <p class="font-20px no-margin semi-bold">
          Campagnes ({{ dataSource.data.length || 'Aucune' }})
        </p>
      </div>
    </div>
    <div style="height:10px"></div>
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
      <div style="height: 55px;" fxLayoutAlign="start center">
        <button fxFlex="210px" mat-raised-button color="accent" style="height: 40px;"
                (click)="updateCampaigns()" [disabled]="isLoading || isLoadingStats">
          Mettre à jour les campagnes
        </button>
      </div>
      <div fxFlex="10px"></div>
      <div style="height: 55px;" fxLayoutAlign="start center">
        <button fxFlex="210px" mat-raised-button color="primary" style="height: 40px;"
                (click)="updateCampaignsStatisticsLastWeek()" [disabled]="isLoading || isLoadingStats">
          Mettre à jour les statistiques
        </button>
      </div>
    </div>
    <div style="height: 10px;"></div>
    <div fxLayoutAlign="start center">
      <div fxFlex="100">
        <mat-form-field appearance="standard" style="width: 100%;">
          <mat-label>Recherche</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Martin" #input>
        </mat-form-field>
        <div class="box no-scroll-bar" style="overflow: scroll">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-hover pollster-table" matSort>

            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="light-black-bg-color white-text font-14px">
                Identifiant
              </th>
              <td mat-cell class="font-16px"
                  *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- Code Column -->
            <ng-container matColumnDef="code">
              <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef> Code
              </th>
              <td mat-cell class="font-16px"
                  *matCellDef="let element"> {{element.code}} </td>
            </ng-container>

            <!-- Start date Column -->
            <ng-container matColumnDef="start_date">
              <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef mat-sort-header>
                Date de début
              </th>
              <td mat-cell class="font-16px"
                  *matCellDef="let element"> {{element.start_date | date: 'MM/YYYY'}} </td>
            </ng-container>

            <!-- Paid hours Column -->
            <ng-container matColumnDef="paid_hours">
              <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef mat-sort-header>
                Heures payées
              </th>
              <td mat-cell class="font-16px"
                  *matCellDef=" let element"> {{element.paid_hours}} </td>
            </ng-container>

            <!-- Injected contacts Column -->
            <ng-container matColumnDef="injected_contacts">
              <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef mat-sort-header>
                Contacts injectés
              </th>
              <td mat-cell class="font-16px"
                  *matCellDef=" let element"> {{element.injected_contacts}} </td>
            </ng-container>


            <!-- Is finished Column -->
            <ng-container matColumnDef="is_finished">
              <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef>
                Statut
              </th>
              <td mat-cell class="font-16px"
                  *matCellDef=" let element">
                <span *ngIf="element.is_finished" style="color: red;">Campagne terminée</span>
                <span *ngIf="!element.is_finished" style="color: green">Campagne en cours</span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="onCampaignClicked(element)"></tr>

            <!-- Row shown when there is no matching data. -->
            <ng-container *ngIf="!isLoading">
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6" style="border: 0 solid;">
                  Aucune donnée ne correspond à la recherche "{{input.value}}"
                </td>
              </tr>
            </ng-container>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                         showFirstLastButtons
                         aria-label="Select page of periodic elements">
          </mat-paginator>
          <div *ngIf="isLoading"
               style="display: flex; justify-content: center; align-items: center;">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate">
            </mat-progress-spinner>
          </div>
        </div>
      </div>
    </div>
    <div style="height:20px"></div>
  </div>
</div>
<app-spinner-dialog *ngIf="isLoadingStats">
  Les statistiques sont entrains d'être mises à jours.
  <br/>
  Cela peut prendre quelques temps.
  <br/>
  Merci de patienter.
</app-spinner-dialog>
