import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {CampaignModel} from "../../../models/campaign/campaign.model";
import {AdminCampaignService} from "../../../services/admin/admin-campaign.service";
import {DatePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../../dialog/confirm/confirm-dialog.component";
import {StatisticModel} from "../../../models/campaign/statistic.model";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-admin-campaign-detail',
  templateUrl: './admin-campaign-detail.component.html',
  styleUrls: ['./admin-campaign-detail.component.scss']
})
export class AdminCampaignDetailComponent implements OnInit {

  isLoading = true;
  isLoadingStats = false;
  isFormDisabled = false;
  isFinished = false;
  isResetStats = false;
  showReports = false;

  minDate = new Date(2018, 0, 1);
  maxDate = new Date((new Date()).getFullYear() + 1, 0, 1);
  campaign: CampaignModel = null;
  dataSource = new MatTableDataSource<StatisticModel>();

  constructor(private datePipe: DatePipe,
              private adminCampSv: AdminCampaignService,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {

    this.loadCampaign();

  }

  loadCampaign() {

    this.isFormDisabled = true;
    this.isResetStats = true;
    this.route.params.subscribe((params: Params) => {
      if (params['campaignId']) {
        this.adminCampSv.fetchCampaign(params['campaignId']).subscribe((campaign: CampaignModel) => {
          this.campaign = campaign;
          this.isFinished = this.campaign.is_finished;
          this.fetchStatisticsForCampaign(this.campaign.id);
        });
      } else {
        this.router.navigate(['admin/campaigns']);
      }
    });

  }

  saveCampaign() {

    this.isFormDisabled = true;
    this.campaign.start_date = this.datePipe.transform(this.campaign.start_date, 'dd/MM/YYYY');
    this.adminCampSv.updateCampaign(this.campaign).subscribe(() => {
      this.loadCampaign();
    });

  }

  reportsOrCampaignClicked() {

    this.showReports = !this.showReports;
    this.loadCampaign();

  }

  fetchStatisticsForCampaign(campaignId: number) {

    this.adminCampSv.fetchStatisticsForCampaign(campaignId).subscribe((statistics: StatisticModel[]) => {

      this.dataSource.data = statistics;
      this.isLoading = false;
      this.isFormDisabled = false;
      this.isResetStats = false;
    });

  }

  updateCampaignStatistics() {

    this.dialog.open(ConfirmDialogComponent, {
      minWidth: '20%',
      data: {
        message: `Êtes-vous sûr de vouloir mettre à jour les toutes statiques pour la campagne ${this.campaign.code} ?`,
        warningMessage: 'Cette action peut prendre quelque temps !'
      },
      autoFocus: false
    }).afterClosed().subscribe(event => {
      if (event) {
        if (event.data) {
          this.isLoadingStats = true;
          this.isFormDisabled = true;
          this.adminCampSv.updateCampaignStatistics(this.campaign.id).subscribe(() => {
            this.loadCampaign();
            this.isLoadingStats = false;
            this.isFormDisabled = false;
          });
        }
      }
    });

  }

}
