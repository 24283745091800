<h1 mat-dialog-title fxLayoutAlign="center start">{{name}}</h1>
<div mat-dialog-content style="overflow: scroll" class="scroll-bar-orange-thumb">
  <div fxFlex="100">
    <mat-form-field appearance="standard" style="width: 100%;">
      <mat-label>Recherche</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Martin" #input>
    </mat-form-field>
    <div class="box no-scroll-bar" style="overflow: scroll;">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 pollster-table">

        <ng-container *ngFor="let column of displayedColumns; let i = index">
          <ng-container [matColumnDef]="column">
            <ng-container *ngIf="column !== 'button'">
              <th mat-header-cell *matHeaderCellDef
                  class="light-black-bg-color white-text font-14px"> {{ displayedLabels[i] }}</th>
              <td mat-cell class="font-16px"
                  *matCellDef="let element"> {{ column.split('_')[1] === 'date' ? (element[column] | date: 'MM/YYYY') : element[column] }} </td>
            </ng-container>
            <ng-container *ngIf="column === 'button'">
              <th mat-header-cell class="light-black-bg-color" *matHeaderCellDef style="width: 60px">
              </th>
              <td mat-cell *matCellDef=" let element">
                <button mat-mini-fab style="height: 30px; width: 30px;" color="primary" (click)="onAddClicked(element)">
                  <mat-icon style="margin-top: -10px;">add</mat-icon>
                </button>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [colSpan]="dataSource.data.length" style="border: 0 solid;">
              Aucune donnée ne correspond à la recherche "{{input.value}}"
            </td>
          </tr>
        </ng-container>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                     showFirstLastButtons
                     aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
    <div style="height: 10px;"></div>
  </div>
</div>
<div style="height: 10px;"></div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-raised-button color="accent" mat-dialog-close="true">Fermer</button>
</div>
<div style="height: 5px;"></div>
