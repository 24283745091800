import {Component, OnInit} from '@angular/core';
import {ReportService} from 'src/app/services/report.service';
import {CampaignModel} from "../../models/campaign/campaign.model";
import {CampaignService} from "../../services/campaign.service";
import {ReportModel} from "../../models/campaign/report.model";
import * as _ from "lodash";
import {DatePipe} from "@angular/common";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-dashboard-files',
  templateUrl: './dashboard-files.component.html',
  styleUrls: ['./dashboard-files.component.scss']
})
export class DashboardFilesComponent implements OnInit {

  reports: ReportModel[] = [];

  selectAllCheckbox = false;
  reportPreviewed: ReportModel = null;
  reportUrl: any = null

  reportDates: { value: string, viewValue: string }[];
  allDates = {value: 'all', viewValue: 'Voir tous les documents'};

  selected: string = null;

  currentCampaign: CampaignModel = null;
  isLoading = true;
  isLoadingReports = true;

  constructor(private campaignSv: CampaignService, private reportSv: ReportService, private datePipe: DatePipe) {
  }

  ngOnInit(): void {

    this.campaignSv.currentCampaign.subscribe((currentCampaign: CampaignModel) => {

      if (currentCampaign) {

        if (this.currentCampaign !== currentCampaign) {

          this.isLoading = true;
          this.reportUrl = null;
          this.reportPreviewed = null;
          this.currentCampaign = currentCampaign;
          this.reportSv.fetchCampaignsReports(this.currentCampaign.id).subscribe((reports: ReportModel[]) => {

            this.reports = reports;

            if (this.reports.length > 0) {

              this.reportDates = this.getDatesOfReports(this.reports) as [];
              this.reportDates = [this.allDates].concat(this.reportDates);

              this.selected = this.reportDates[1].value;
              this.fetchReportsByDate(this.selected);

            }
            this.isLoading = false;
          });
        }
      } else {
        this.isLoading = false;
      }
    });

  }

  fetchReportsByDate(date: string) {

    this.isLoadingReports = true;
    this.selectAllCheckbox = false;

    if (date === 'all') {
      this.reportSv.fetchCampaignsReports(this.currentCampaign.id).subscribe((reports) => {
        this.reports = reports;
        this.isLoadingReports = false;
        this.isLoading = false;
      });
      return;
    }

    this.reportSv.fetchCampaignsReportsByDate(this.currentCampaign.id, date).subscribe((reports) => {
      this.reports = reports;
      this.isLoadingReports = false;
      this.isLoading = false;
    });

  }

  checkAll() {

    this.selectAllCheckbox = !this.selectAllCheckbox;
    if (this.selectAllCheckbox) {
      this.reports.map(r => r.isChecked = true);
    } else {
      this.reports.map(r => r.isChecked = false);
    }

  }

  checkFile(report) {

    if (this.selectAllCheckbox) {
      this.selectAllCheckbox = false;
    }

    report.isChecked = !report.isChecked;

    if (this.reports.every(r => r.isChecked === true) && !this.selectAllCheckbox) {
      this.selectAllCheckbox = true;
    }

  }

  isFileChecked(report) {

    return report.isChecked === true;

  }

  download() {

    let reportsToDownload: ReportModel[];
    reportsToDownload = this.reports.filter(r => r.isChecked === true);
    this.selectAllCheckbox = true;
    this.checkAll();

    reportsToDownload.forEach((report: ReportModel) => {
      this.reportSv.downloadReportById(report.id).subscribe((pdfUrl) => {

        const pdfLink = document.createElement('a');
        pdfLink.href = pdfUrl;
        pdfLink.download = report.campaign.code + '_' + this.datePipe.transform(report.date, 'dd/MM/YYYY');
        pdfLink.click();
      });
    });

  }

  preview(report: ReportModel) {

    this.reportSv.downloadReportById(report.id).subscribe((pdfUrl) => {
      this.reportPreviewed = report;
      this.reportUrl = pdfUrl;
    });

  }

  sendByEmail() {

    let reportsToSend: ReportModel[];
    const listToExecute = [];
    reportsToSend = this.reports.filter(r => r.isChecked === true);
    this.selectAllCheckbox = true;
    this.checkAll();
    this.isLoadingReports = true;

    const dates = this.getDatesOfReports(reportsToSend, true);

    for (const date of dates) {
      const repsFiltered = _.filter(reportsToSend, (r) => {
        return r.date.getTime() === date;
      });

      listToExecute.push(this.reportSv.sendReportsByEmail(repsFiltered));
    }

    forkJoin(listToExecute).subscribe(() => {
      this.isLoadingReports = false;
    });

  }

  getDatesOfReports(reports: ReportModel[], onlyDates = false) {

    return _(reports)
      .sortedUniqBy((report) => {
        return report.date.getTime();
      })
      .orderBy(['date'], ['desc'])
      .map((report) => {
        const date = this.datePipe.transform(report.date, 'dd/MM/YYYY');
        return onlyDates ? report.date.getTime() : {value: date, viewValue: date}
      })
      .value();

  }

}
