import {DateUtils} from "../../utils/date-utlis.model";

export class CampaignModel {

  id: number;
  code: string;
  name: string;
  start_date: Date | string;
  paid_hours: number;
  injected_contacts: number;
  is_finished: boolean;
  last_stat_update: Date;

  static generateModel(json: any): CampaignModel {

    const campaign = new CampaignModel();
    campaign.id = json.id;
    campaign.code = json.code;
    campaign.name = json.name;
    campaign.start_date = DateUtils.date(json.start_date);
    campaign.paid_hours = json.paid_hours;
    campaign.injected_contacts = json.injected_contacts;
    campaign.is_finished = json.is_finished;
    campaign.last_stat_update = DateUtils.date(json.last_stat_update);

    return campaign;
  }

  static generateModels(jsonList: any): CampaignModel[] {

    const list = [];

    if (jsonList) {
      for (const json of jsonList) {
        list.push(CampaignModel.generateModel(json));
      }
    }
    return list;

  }
}
