import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ErrorModel} from "../../models/error.model";

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  errorName = ErrorModel.genericError().name;
  errorMessage = ErrorModel.genericError().msg;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ErrorModel) {
  }

  ngOnInit(): void {
    if (this.data.name) {
      this.errorName = this.data.name;
    }
    if (this.data.msg) {
      this.errorMessage = this.data.msg;
    }
  }

}
