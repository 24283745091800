import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {LayoutModule} from "@angular/cdk/layout";
import {AppComponent} from './app.component';
import {DatePipe} from "@angular/common";
import {RouterModule} from "@angular/router";
import {SignInComponent} from './auth/sign-in/sign-in.component';
import {FormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {HttpClientModule} from "@angular/common/http";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {AppRoutingModule} from "./app-routing/app-routing.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {DashboardRootComponent} from './dashboard/dashboard-root.component';
import {ErrorDialogComponent} from './dialog/error/error-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {DashboardSidebarComponent} from './dashboard/dashboard-sidebar/dashboard-sidebar.component';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {DashboardFilesComponent} from './dashboard/dashboard-files/dashboard-files.component';
import {DashboardHomeComponent} from './dashboard/dashboard-home/dashboard-home.component';
import {DashboardHeaderComponent} from './dashboard/dashboard-header/dashboard-header.component';
import {MatMenuModule} from "@angular/material/menu";
import {MatTableModule} from "@angular/material/table";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {AdminRootComponent} from './admin/admin-root.component';
import {AdminHeaderComponent} from "./admin/admin-header/admin-header.component";
import {AdminSidebarComponent} from "./admin/admin-sidebar/admin-sidebar.component";
import {AdminClientsComponent} from './admin/admin-clients/admin-clients.component';
import {AdminCampaignsComponent} from './admin/admin-campaigns/admin-campaigns.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSelectModule} from "@angular/material/select";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {AdminClientDetailComponent} from './admin/admin-clients/admin-client-detail/admin-client-detail.component';
import {SuccessDialogComponent} from './dialog/success/success-dialog.component';
import {
  AdminClientCampaignsComponent
} from './admin/admin-clients/admin-client-campaigns/admin-client-campaigns.component';
import {MatPaginatorModule} from "@angular/material/paginator";
import {TableDialogComponent} from './dialog/table/table-dialog.component';
import {
  AdminCampaignDetailComponent
} from './admin/admin-campaigns/admin-campaign-detail/admin-campaign-detail.component';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatSortModule} from "@angular/material/sort";
import {CustomDateAdapter} from "./custom-date-adapter";
import {
  AdminCampaignReportsComponent
} from './admin/admin-campaigns/admin-campaign-reports/admin-campaign-reports.component';
import {AddPdfDialogComponent} from './dialog/add-pdf/add-pdf-dialog.component';
import {NgxFileDropModule} from "ngx-file-drop";
import {AddClientDialogComponent} from './dialog/add-client/add-client-dialog.component';
import { ChangePasswordDialogComponent } from './dialog/change-password/change-password-dialog.component';
import {SpinnerDialogComponent} from "./dialog/spinner/spinner-dialog.component";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { ConfirmDialogComponent } from './dialog/confirm/confirm-dialog.component';
import { StatisticsContainerComponent } from './shared/statistics-container/statistics-container.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    DashboardRootComponent,
    ErrorDialogComponent,
    DashboardSidebarComponent,
    DashboardFilesComponent,
    DashboardHomeComponent,
    DashboardHeaderComponent,
    AdminRootComponent,
    AdminHeaderComponent,
    AdminSidebarComponent,
    AdminClientsComponent,
    AdminCampaignsComponent,
    AdminClientDetailComponent,
    SuccessDialogComponent,
    AdminClientCampaignsComponent,
    TableDialogComponent,
    AdminCampaignDetailComponent,
    AdminCampaignReportsComponent,
    AddPdfDialogComponent,
    AddClientDialogComponent,
    ChangePasswordDialogComponent,
    SpinnerDialogComponent,
    ConfirmDialogComponent,
    StatisticsContainerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    FlexLayoutModule,
    HttpClientModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    MatTableModule,
    MatCheckboxModule,
    PdfViewerModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    LayoutModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    NgxFileDropModule,
    MatSlideToggleModule
  ],
  providers: [
    DatePipe,
    MatDatepickerModule,
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: DateAdapter, useClass: CustomDateAdapter}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
