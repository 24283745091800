export class ErrorModel {

  public success: boolean ;
  public name: string;
  public msg: string;

  static generateModel(json: any): ErrorModel {

    const error = new ErrorModel();
    error.success = json.success;
    error.msg = json.msg;

    return error;
  }

  static genericError(): ErrorModel {

    const error = new ErrorModel();
    error.success = false;
    error.name = 'Erreur';
    error.msg = 'Une erreur inconnue s\'est produite. Essayez à nouveau plus tard.';

    return error;

  }

  static catchError(err: any) {

    if (err) {
      if (err.error) {
            return ErrorModel.generateModel(err.error);
      }
    }

    return ErrorModel.genericError();

  }

}
