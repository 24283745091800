import {Component, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {UserModel} from 'src/app/models/user.model';
import {TokenModel} from "../../models/token.model";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  isLoading = false;

  constructor(private authSv: AuthService,
              private router: Router) {
  }

  ngOnInit() {
    UserModel.removeCurrent();
    TokenModel.removeCurrent();
  }

  onSignInClicked(form: NgForm) {

    this.isLoading = true;

    let email = form.value.email;

    if (email) {
      email = email.toLowerCase();
    }

    const password = form.value.password;

    this.authSv.signIn(email, password).subscribe((data) => {
      if (data.user.role === UserModel.Roles.client) {
        this.router.navigate(['dashboard']);
      } else if (data.user.role === UserModel.Roles.admin) {
        this.router.navigate(['admin']);
      }
    }, () => {
      this.isLoading = false;
    });
  }
}
