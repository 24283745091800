import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NgxFileDropEntry} from "ngx-file-drop";
import {AdminReportService} from "../../services/admin/admin-report.service";
import {DatePipe} from '@angular/common';
import {CampaignModel} from 'src/app/models/campaign/campaign.model';

@Component({
  selector: 'app-add-pdf',
  templateUrl: './add-pdf-dialog.component.html',
  styleUrls: ['./add-pdf-dialog.component.scss']
})
export class AddPdfDialogComponent implements OnInit {

  successName = 'Ajouter un document';
  campaign: CampaignModel = null
  file: NgxFileDropEntry = null;
  isLoading = false;
  date = new Date();
  minDate = new Date(2018, 0, 1);
  maxDate = new Date((new Date()).getFullYear() + 1, 0, 1);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { name: string, campaign: CampaignModel },
              private adminRepSv: AdminReportService,
              private datePipe: DatePipe,
              private dialogRef: MatDialogRef<AddPdfDialogComponent>,
  ) {
  }

  ngOnInit(): void {

    if (this.data.name) {
      this.successName = this.data.name;
    }
    if (this.data.campaign) {
      this.campaign = this.data.campaign;
    }

  }

  dropped(file: NgxFileDropEntry[]) {
    this.file = file[file.length - 1];
  }

  removeFile() {
    this.file = null;
  }

  saveReports() {
    if (this.date instanceof Date)

      // Is it a file?
      if (this.file.fileEntry.isFile) {
        const fileEntry = this.file.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // You could upload it like this:
          const formData = new FormData()
          formData.append('file', file);
          formData.append('campaign_id', this.campaign.id.toString());
          formData.append('action', 'add');
          formData.append('date', this.datePipe.transform(this.date, 'dd/MM/YYYY'));
          this.isLoading = true;
          this.adminRepSv.addReport(formData).subscribe(() => {
            this.dialogRef.close({event: 'close', data: true});
          });
        });
      }
  }
}
