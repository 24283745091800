<div style="height: 100%; overflow: scroll"
     *ngIf="!isLoading && campaign" fxLayoutAlign="center start" class="scroll-bar-orange-thumb">
  <div fxFlex="98">
    <div style="height:10px"></div>
    <div class="box" style="height: 50px;">
      <div fxFlex="10px"></div>
      <div fxLayoutAlign="start center" fxFlex="140px">
        <button fxFlex="140px" mat-raised-button color="accent" style="height: 40px;"
                (click)="reportsOrCampaignClicked()">
          {{ !showReports ? 'Documents' : 'Campagnes' }}
        </button>
      </div>
      <div fxFlex="10px"></div>
      <div fxLayoutAlign="center center" fxFlex>
        <p class="font-20px no-margin semi-bold">
          Campagne numéro : {{ campaign.id }}
        </p>
      </div>
      <div fxFlex="160px" fxHide.lt-md="true"></div>
    </div>
    <div style="height:10px"></div>
    <div *ngIf="!showReports">
      <form #f="ngForm">
        <div style="height: 55px;" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
          <button fxFlex="210px" mat-raised-button color="primary" style="height: 40px;"
                  (click)="updateCampaignStatistics()" [disabled]="isLoadingStats || !f.valid || isFinished">
            Mettre à jour les statistiques
          </button>
          <div fxFlex="10px"></div>
          <p fxFlex class="font-16px no-margin">Dernière mise à jour
            : {{ (campaign.last_stat_update | date: 'dd/MM/YYYY') || 'Aucune' }}</p>
        </div>
        <div style="height:10px"></div>
        <div fxLayoutAlign="end center" fxLayoutAlign.xs="center start">
          <button fxFlex="140px" mat-raised-button style="height: 40px;"
                  [disabled]="!f.valid || !f.dirty" (click)="saveCampaign(); f.control.markAsPristine()"
                  color="primary">
            Sauvegarder
          </button>
          <div fxFlex="20px"></div>
          <button fxFlex="140px" mat-raised-button style="height: 40px;"
                  [disabled]="!f.valid || !f.dirty" (click)="loadCampaign(); f.control.markAsPristine()"
                  color="warn">
            Annuler
          </button>
        </div>
        <div style="height:20px"></div>
        <div fxLayout="row wrap">
          <div style="height:10px"></div>
          <mat-slide-toggle fxFlex color="primary" [disabled]="isFormDisabled"
                            [(ngModel)]="campaign.is_finished" name="is_finished">
            {{ campaign.is_finished ? 'Campagne termninée' : 'Campagne en cours' }}
          </mat-slide-toggle>
          <div fxHide.gt-xs="true" style="height: 40px;"></div>
          <div class="box" fxFlex>
            <div style="height:10px"></div>
            <div class="mat-form-field-no-bottom-space" fxLayoutAlign="start center" style="min-width: 200px;">
              <div fxFlex="10px"></div>
              <mat-form-field fxFlex appearance="standard">
                <mat-label>Nom de la campagne</mat-label>
                <input [disabled]="isFormDisabled || isFinished" [(ngModel)]="campaign.name" matInput
                       placeholder="Campagne Pollster"
                       name="name"
                       type="text" required>
              </mat-form-field>
              <div fxFlex="10px"></div>
            </div>
            <div style="height:10px"></div>
          </div>
          <div style="height:10px"></div>
        </div>
        <div style="height:20px"></div>
        <div class="box">
          <div style="height:10px"></div>
          <div class="mat-form-field-no-bottom-space" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
            <div fxFlex="10px"></div>
            <mat-form-field appearance="standard" fxFlex ngStyle.xs="width: 95%;">
              <mat-label>Code campagne</mat-label>
              <input [disabled]="isFormDisabled || isFinished" [(ngModel)]="campaign.code" matInput
                     placeholder="POLL_CODE"
                     name="code"
                     type="text" required>
            </mat-form-field>
            <div fxFlex="20px" fxFlex.xs="10px"></div>
            <mat-form-field appearance="standard" fxFlex ngStyle.xs="width: 95%;">
              <mat-label>Date de début</mat-label>
              <input matInput [matDatepicker]="picker" name="start_date" required
                     [disabled]="isFormDisabled || isFinished"
                     [min]="minDate"
                     [max]="maxDate"
                     [ngModel]="campaign.start_date " (ngModelChange)="campaign.start_date = $event">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div fxFlex="10px"></div>
          </div>
          <div style="height:10px"></div>
        </div>
        <div style="height:20px" ngStyle.lt-md="height: 10px;"></div>
        <div class="box">
          <div style="height:10px"></div>
          <div class="mat-form-field-no-bottom-space" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
            <div fxFlex="10px"></div>
            <mat-form-field appearance="standard" fxFlex ngStyle.xs="width: 95%;">
              <mat-label>Heures payées</mat-label>
              <input [disabled]="isFormDisabled || isFinished" [(ngModel)]="campaign.paid_hours" matInput
                     placeholder="POLL_CODE"
                     name="paid_hours"
                     type="number" required>
            </mat-form-field>
            <div fxFlex="20px" fxFlex.xs="10px"></div>
            <mat-form-field appearance="standard" fxFlex ngStyle.xs="width: 95%;">
              <mat-label>Contacts injectés</mat-label>
              <input [disabled]="isFormDisabled || isFinished" [(ngModel)]="campaign.injected_contacts" matInput
                     placeholder="Pollster"
                     name="injected_contacts"
                     type="number" required>
            </mat-form-field>
            <div fxFlex="10px"></div>
          </div>
          <div style="height:10px"></div>
        </div>
        <div style="height:20px" ngStyle.lt-md="height: 10px;"></div>
      </form>
      <mat-divider style="width: 100%;"></mat-divider>
      <div style="height:10px"></div>
      <app-statistics-container *ngIf="!isResetStats"
        [dataSource]="dataSource"
                                [campaign]="campaign"
                                [isFilter]="false">
      </app-statistics-container>
    </div>
    <app-admin-campaign-reports *ngIf="showReports" [isFinished]="isFinished" [campaign]="campaign">
    </app-admin-campaign-reports>
    <div style="height:10px"></div>
  </div>
</div>
<div *ngIf="isLoading"
     style="display: flex; justify-content: center; align-items: center; height: 100%;">
  <mat-progress-spinner
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
</div>
<app-spinner-dialog *ngIf="isLoadingStats">
  Les statistiques de la campagne {{ campaign.code }} sont entrains d'être mises à jours.
  <br/>
  Cela peut prendre quelques minutes.
  <br/>
  Merci de patienter.
</app-spinner-dialog>
