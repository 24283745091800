<div *ngIf="dataSource.data.length > 0" style="overflow: scroll; height: calc(100% - 100px); "
     ngStyle.lt-md="height: calc(100% - 90px);" class="scroll-bar-orange-thumb">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="center center">
    <div fxFlex="48" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center"
         fxLayoutAlign.lt-md="center center" ngStyle.lt-md="width: 100%;">
      <div class="box light-black-bg-color" fxLayoutAlign="center center" fxFlex fxFlex.lt-md="40px"
           ngStyle.lt-md="width: 100%;">
        <div>
          <div style="height: 25px;"></div>
          <p class="no-margin text-overflow-ellipsis white-text" ngClass.sm="font-14px" ngClass.gt-sm="font-16px"
             ngClass.xs="font-16px">
            NOMBRE DE CONTACTS
          </p>
          <div style="height: 25px;"></div>
        </div>
      </div>
      <div fxFlex="10px"></div>
      <div fxFlex fxFlex.lt-md="35px" fxLayoutAlign.lt-md="center center"
           ngStyle.lt-md="width: 100%;" fxLayout.lt-md="row" fxLayout="column">
        <div class="box orange-bg-color" fxLayoutAlign="center center" ngStyle.lt-md="width: 100%;">
          <div fxFlex="10px"></div>
          <div style="width: calc(100% - 20px);">
            <div style="height: 5px;"></div>
            <p class="no-margin text-overflow-ellipsis" ngClass.sm="font-12px" ngClass.gt-sm="font-14px"
               ngClass.xs="font-14px">
              INJECTES : {{ campaign.injected_contacts }}
            </p>
            <div style="height: 5px;"></div>
          </div>
        </div>
        <div style="height: 10px;" fxHide.lt-md="true"></div>
        <div fxFlex="10px" fxHide.gt-sm="true"></div>
        <div class="box orange-bg-color" fxLayoutAlign="center center" ngStyle.lt-md="width: 100%;">
          <div fxFlex="10px"></div>
          <div style="width: calc(100% - 20px);">
            <div style="height: 5px;"></div>
            <p class="no-margin text-overflow-ellipsis" ngClass.sm="font-12px" ngClass.gt-sm="font-14px"
               ngClass.xs="font-14px">
              TRAITES : {{ totals.treatedCalls }}
            </p>
            <div style="height: 5px;"></div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="4" fxFlex.lt-md="20px"></div>
    <div fxFlex="48" fxFlex.lt-md fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center"
         fxLayoutAlign.lt-md="center center" ngStyle.lt-md="width: 100%;">
      <div class="box light-black-bg-color" fxLayoutAlign="center center" fxFlex fxFlex.lt-md="40px"
           ngStyle.lt-md="width: 100%;">
        <div>
          <div style="height: 25px;"></div>
          <p class="no-margin white-text" ngClass.sm="font-14px" ngClass.gt-sm="font-16px" ngClass.xs="font-16px">
            NOMBRE D'HEURES
          </p>
          <div style="height: 25px;"></div>
        </div>
      </div>
      <div fxFlex="10px"></div>
      <div fxFlex fxFlex.lt-md="35px" fxLayoutAlign.lt-md="center center"
           ngStyle.lt-md="width: 100%;" fxLayout.lt-md="row" fxLayout="column">
        <div class="box orange-bg-color" fxLayoutAlign="center center" ngStyle.lt-md="width: 100%;">
          <div fxFlex="10px"></div>
          <div style="width: calc(100% - 20px);">
            <div style="height: 5px;"></div>
            <p class="no-margin text-overflow-ellipsis" ngClass.sm="font-12px" ngClass.gt-sm="font-14px"
               ngClass.xs="font-14px">
              ACHETEES : {{ campaign.paid_hours }}
            </p>
            <div style="height: 5px;"></div>
          </div>
        </div>
        <div style="height: 10px;" fxHide.lt-md="true"></div>
        <div fxFlex="10px" fxHide.gt-sm="true"></div>
        <div class="box orange-bg-color" fxLayoutAlign="center center" ngStyle.lt-md="width: 100%;">
          <div fxFlex="10px"></div>
          <div style="width: calc(100% - 20px);">
            <div style="height: 5px;"></div>
            <p class="no-margin text-overflow-ellipsis" ngClass.sm="font-12px" ngClass.gt-sm="font-14px"
               ngClass.xs="font-14px">
              REALISEES : {{ totals.numberOfHours }}
            </p>
            <div style="height: 5px;"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="height:20px"></div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
    <div class="box light-black-bg-color" fxLayoutAlign="center center" fxFlex="350px" fxFlex.xs="50px"
         ngStyle.xs="width: 100%;">
      <div>
        <div style="height: 5px;"></div>
        <p class="font-14px no-margin white-text" style="word-break: break-all;">
          MOYENNE DE CONTACTS ARGUMENTES PAR HEURE
        </p>
        <div style="height: 5px;"></div>
      </div>
    </div>
    <div fxFlex="10px"></div>
    <div class="box" fxLayoutAlign="center center" fxFlex="80px" fxFlex.xs="30px" ngStyle.xs="width: 100%;">
      <div>
        <div style="height: 5px;"></div>
        <p class="no-margin font-14px text-overflow-ellipsis">
          {{ averages.contactsPerHour }}
        </p>
        <div style="height: 5px;"></div>
      </div>
    </div>
  </div>
  <div ngStyle.gt-xs="height: 10px;" style="height:20px"></div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
    <div fxFlex="4"></div>
    <div class="box light-black-bg-color" fxLayoutAlign="center center" fxFlex="350px" fxFlex.xs="50px"
         ngStyle.xs="width: 100%;">
      <div>
        <div style="height: 5px;"></div>
        <p class="font-14px no-margin white-text" style="word-break: break-all;">
          MOYENNE DE PROJETS DETECTES PAR HEURE
        </p>
        <div style="height: 5px;"></div>
      </div>
    </div>
    <div fxFlex="10px"></div>
    <div class="box" fxLayoutAlign="center center" fxFlex="80px" fxFlex.xs="30px" ngStyle.xs="width: 100%;">
      <div>
        <div style="height: 5px;"></div>
        <p class="no-margin font-14px text-overflow-ellipsis">
          {{ averages.detectedProjectPerHour }}
        </p>
        <div style="height: 5px;"></div>
      </div>
    </div>
  </div>
  <div ngStyle.gt-xs="height: 10px;" style="height:20px"></div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
    <div fxFlex="8"></div>
    <div class="box light-black-bg-color" fxLayoutAlign="center center" fxFlex="350px" fxFlex.xs="50px"
         ngStyle.xs="width: 100%;">
      <div>
        <div style="height: 5px;"></div>
        <p class="font-14px no-margin white-text" style="word-break: break-all;">
          MOYENNE DU FICHIER ARGUMENTE
        </p>
        <div style="height: 5px;"></div>
      </div>
    </div>
    <div fxFlex="10px"></div>
    <div class="box" fxLayoutAlign="center center" fxFlex="80px" fxFlex.xs="30px" ngStyle.xs="width: 100%;">
      <div>
        <div style="height: 5px;"></div>
        <p class="no-margin font-14px text-overflow-ellipsis">
          {{ this.averages.fileArgu }} %
        </p>
        <div style="height: 5px;"></div>
      </div>
    </div>
  </div>
  <div style="height:20px"></div>
  <div fxLayoutAlign="start center">
    <div fxFlex="100">
      <mat-form-field appearance="standard" style="width: 100%;" fxHide="{{!isFilter}}">
        <mat-label>Recherche</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. S1" #input>
      </mat-form-field>
      <div class="box no-scroll-bar" style="overflow: scroll">
        <div class="light-black-bg-color" style="width: 100%; min-width: 950px;">
          <div fxFlex="55">
            <div style="height: 10px"></div>
            <p class="font-14px no-margin white-text text-center">
              NOMBRE PAR SEMAINE
            </p>
            <div style="height: 10px"></div>
          </div>
          <div fxFlex="45">
            <div style="height: 10px"></div>
            <p class="font-14px no-margin white-text text-center">
              REPARTITION DES PROJETS DETECTES
            </p>
            <div style="height: 10px"></div>
          </div>
        </div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 pollster-table">

          <!-- Week Column -->
          <ng-container matColumnDef="week">
            <th mat-header-cell class="light-black-bg-color white-text font-14px"
                style="border-left: 0 solid!important;"
                *matHeaderCellDef> Semaine
            </th>
            <td mat-cell class="orange-bg-color black-text font-16px" style="border-left: 0 solid!important;"
                *matCellDef="let element"> {{element.week}} </td>
          </ng-container>

          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="light-black-bg-color white-text font-14px"> Date</th>
            <td mat-cell class="light-gray-color black-text font-16px"
                *matCellDef="let element">
              {{ element.start_date | date: 'dd/MM' }} au {{ element.end_date | date: 'dd/MM' }} </td>
          </ng-container>

          <!-- Completed hours Column -->
          <ng-container matColumnDef="completedHours">
            <th mat-header-cell class="orange-bg-color black-text font-14px" *matHeaderCellDef> Heures réalisées
            </th>
            <td mat-cell class="light-gray-color black-text font-16px"
                *matCellDef="let element"> {{element.number_of_hours}} </td>
          </ng-container>

          <!-- Identified projects Column -->
          <ng-container matColumnDef="identifiedProjects">
            <th mat-header-cell class="orange-bg-color black-text font-14px" *matHeaderCellDef> Projet detectés</th>
            <td mat-cell class="light-gray-color black-text font-16px"
                *matCellDef="let element"> {{element.detected_project}} </td>
          </ng-container>

          <!-- Argued contacts projects Column -->
          <ng-container matColumnDef="arguedContacts">
            <th mat-header-cell class="orange-bg-color black-text font-14px" *matHeaderCellDef> Contacts argu</th>
            <td mat-cell class="light-gray-color black-text font-16px"
                *matCellDef="let element"> {{element.argu_contact}} </td>
          </ng-container>

          <!-- Deals Column -->
          <ng-container matColumnDef="deals">
            <th mat-header-cell class="orange-bg-color black-text font-14px" *matHeaderCellDef> Transactions</th>
            <td mat-cell class="font-16px" *matCellDef="let element"> {{element.trx}} </td>
          </ng-container>

          <!-- Deal tracking Column -->
          <ng-container matColumnDef="dealTracking">
            <th mat-header-cell class="orange-bg-color black-text font-14px" *matHeaderCellDef> Suivi transactions
            </th>
            <td mat-cell class="black-text font-16px" *matCellDef="let element"> {{element.trx_followup}} </td>
          </ng-container>

          <!-- Details Column -->
          <ng-container matColumnDef="details">
            <th mat-header-cell class="orange-bg-color black-text font-14px" *matHeaderCellDef> Informations</th>
            <td mat-cell class="black-text font-16px" *matCellDef="let element"> {{element.info}} </td>
          </ng-container>

          <!-- Bonus Column -->
          <ng-container matColumnDef="bonus">
            <th mat-header-cell class="orange-bg-color black-text font-14px" *matHeaderCellDef> Bonus</th>
            <td mat-cell class="black-text font-16px" *matCellDef="let element"> {{element.bonus}} </td>
          </ng-container>

          <!-- Totals Column -->
          <ng-container matColumnDef="totals">
            <th mat-header-cell class="light-black-bg-color semi-bold white-text font-16px"
                style="border-right: 0 solid!important;" *matHeaderCellDef>
              Totaux
            </th>
            <td mat-cell class="light-black-bg-color semi-bold white-text font-14px" *matCellDef="let element"
                style="border-right: 0 solid!important;">{{element.total}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Row shown when there is no matching data. -->
          <ng-container *ngIf="isFilter">
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="10" style="border: 0 solid;">
                Aucune donnée ne correspond à la recherche "{{ input.value}}"
              </td>
            </tr>
          </ng-container>
        </table>
        <div class="light-black-bg-color" style="width: 100%; min-width: 950px;">
          <div fxFlex="22">
            <div style="height: 10px"></div>
            <p class="font-16px semi-bold no-margin white-text text-center">
              TOTAUX
            </p>
            <div style="height: 10px"></div>
          </div>
          <div fxFlex="9">
            <div style="height: 10px"></div>
            <p class="font-16px semi-bold no-margin white-text text-center">
              {{totals.numberOfHours}}
            </p>
            <div style="height: 10px"></div>
          </div>
          <div fxFlex="3"></div>
          <div fxFlex="10">
            <div style="height: 10px"></div>
            <p class="font-16px semi-bold no-margin white-text text-center">
              {{totals.detectedProject}}
            </p>
            <div style="height: 10px"></div>
          </div>
          <div fxFlex="1"></div>
          <div fxFlex="10">
            <div style="height: 10px"></div>
            <p class="font-16px semi-bold no-margin white-text text-center">
              {{totals.arguContact}}
            </p>
            <div style="height: 10px"></div>
          </div>
          <div fxFlex="21">
            <div style="height: 10px"></div>
            <p class="font-16px semi-bold no-margin white-text text-center">
              {{totals.trxTot}}
            </p>
            <div style="height: 10px"></div>
          </div>
          <div fxFlex="3"></div>
          <div fxFlex="10">
            <div style="height: 10px"></div>
            <p class="font-16px semi-bold no-margin white-text text-center">
              {{totals.info}}
            </p>
            <div style="height: 10px"></div>
          </div>
          <div fxFlex="5">
            <div style="height: 10px"></div>
            <p class="font-16px semi-bold no-margin white-text text-center">
              {{totals.bonus}}
            </p>
            <div style="height: 10px"></div>
          </div>
          <div fxFlex="6">
            <div style="height: 10px"></div>
            <p class="font-16px semi-bold no-margin white-text text-center">
              {{totals.totals}}
            </p>
            <div style="height: 10px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="dataSource.data.length <= 0" style="height: calc(100% - 100px);">
  <div fxLayoutAlign="center center" style="height: 100%;">
    <p class="font-22px no-margin semi-bold text-center">
      Aucune données statistiques n'ont été générée pour le moment!
    </p>
  </div>
</div>
