import {DateUtils} from "../../utils/date-utlis.model";
import {CampaignModel} from "./campaign.model";

export class StatisticModel {

  id: number;
  week: string;
  start_date: Date;
  end_date: Date;
  number_of_hours: number;
  detected_project: number;
  argu_contact: number;
  trx: number;
  trx_followup: number;
  info: number;
  bonus: number;
  treated_calls: number;
  total = 0;
  campaign: CampaignModel;


  static generateModel(json: any): StatisticModel {

    const statistic = new StatisticModel();
    statistic.id = json.id;
    statistic.week = 'S' + json.week;
    statistic.start_date = DateUtils.date(json.start_date);
    statistic.end_date = DateUtils.date(json.end_date);
    statistic.number_of_hours = json.number_of_hours;
    statistic.detected_project = json.detected_project;
    statistic.argu_contact = json.argu_contact;
    statistic.trx = json.trx;
    statistic.trx_followup = json.trx_followup;
    statistic.info = json.info;
    statistic.bonus = json.bonus;
    statistic.treated_calls = json.treated_calls;
    statistic.total = statistic.trx + statistic.trx_followup + statistic.info + statistic.bonus;
    statistic.campaign = CampaignModel.generateModel(json.campaign);

    return statistic;
  }

  static generateModels(jsonList: any): StatisticModel[] {

    const list = [];

    if (jsonList) {
      jsonList.forEach((json, index) => {
        list.push(StatisticModel.generateModel(json));
      })
    }
    return list;

  }
}
