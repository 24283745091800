<div style="height: 100%;"
     *ngIf="!isLoading && currentCampaign">
  <div fxFlex="98">
    <div style="height:10px"></div>
    <div class="box" style="height: 50px;" fxLayoutAlign="center center">
      <p class="font-20px no-margin semi-bold">
        {{ currentCampaign.name || currentCampaign.code.toUpperCase()}} :
        <span *ngIf="currentCampaign.is_finished" style="color: red;">Campagne terminée</span>
        <span *ngIf="!currentCampaign.is_finished" style="color: green">Campagne en cours</span>
      </p>
    </div>
    <div style="height:20px"></div>
    <app-statistics-container [dataSource]="dataSource" [campaign]="currentCampaign">
    </app-statistics-container>
    <div style="height:20px" ngStyle.lt-md="height: 10px;"></div>
  </div>
</div>

<div style="height: 100%;"
     *ngIf="!isLoading && !currentCampaign">
  <div fxFlex="98" fxLayoutAlign="center center">
    <p class="font-20px text-center no-margin">
      Vous n'avez pas de campagne disponible !
    </p>
  </div>
</div>

<div *ngIf="isLoading"
     style="display: flex; justify-content: center; align-items: center; height: 100%;">
  <mat-progress-spinner
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
</div>
