import {CampaignModel} from "./campaign/campaign.model";
import * as _ from "lodash";

export class UserModel {

  private static currentUser: UserModel = null;
  private static storageKey = 'pollsterUser';

  public static Roles = {
    client: 'client',
    admin: 'admin',
    list: function () {
      return [this.client, this.admin];
    }
  };

  id: number;
  first_name: string;
  last_name: string;
  email: string;
  society: string;
  address: string;
  campaigns: CampaignModel[];
  alternative_email: string[];
  role: string;

  static generateModel(json: any): UserModel {

    const user = new UserModel();
    user.id = json.id;
    user.first_name = json.first_name;
    user.last_name = json.last_name;
    user.email = json.email;
    user.society = json.society;
    user.address = json.address;
    user.campaigns = CampaignModel.generateModels(json.campaigns);
    if (json.alternative_email) {
      user.alternative_email = _.map(json.alternative_email, 'email');
    }
    user.role = json.role;

    return user;
  }

  static generateModels(jsonList: any[]): UserModel[] {

    const list = [];

    for (const json of jsonList) {
      const user = this.generateModel(json);
      list.push(user);
    }

    return list;

  }

  static getCurrent(): UserModel {

    if (this.currentUser) {
      return this.currentUser;
    }

    const currentUserJson = localStorage.getItem(this.storageKey);
    if (currentUserJson) {
      this.currentUser = this.generateModel(JSON.parse(currentUserJson));
      return this.currentUser;
    }

    return null;
  }

  static setCurrent(user: UserModel) {

    this.currentUser = user;

    if (user) {
      localStorage.setItem(this.storageKey, JSON.stringify(user));
    } else {
      this.removeCurrent();
    }
  }

  static removeCurrent() {
    this.currentUser = null;
    localStorage.removeItem(this.storageKey);
  }

  getFullName(): string {

    if (this.role === UserModel.Roles.client) {
      if (!this.society) {
        return '';
      }
      return this.society;
    } else if (this.role === UserModel.Roles.admin) {
      if (!this.first_name || !this.last_name) {
        return '';
      }
      return this.first_name + ' ' + this.last_name;
    }

    return '';
  }

  getInitials(): string {

    if (this.role === UserModel.Roles.client) {
      if (this.society.indexOf(' ') >= 0) {
        return this.society.split(' ')[0].substring(0, 1).toUpperCase() + this.society.split(' ')[1].substring(0, 1).toUpperCase();
      } else if (this.society) {
        return this.society.substring(0, 2).toUpperCase();
      }
      return '';
    } else if (this.role === UserModel.Roles.admin) {
      return this.first_name.substring(0, 1).toUpperCase() + this.last_name.substring(0, 1).toUpperCase();
    }
    return '';
  }
}
