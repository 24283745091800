<div class="no-scroll-bar" style="height: 100%; z-index: 10;" ngStyle.lt-md="overflow-y: scroll;"
     fxHide.xs="true" fxFlex="240px" fxFlex.lt-lg="180px">
  <div style="height: 15px;"></div>
  <div fxLayoutAlign="center start">
    <div fxFlex="20px"></div>
    <img mat-card-image src="./assets/pollster-big.png"
         alt="Logo Pollster"
         style="width: 100%; height: 85px; object-fit: contain;">
    <div fxFlex="20px"></div>
  </div>
  <div style="height: 60px;"></div>
  <div *ngIf="campaigns.length > 0" fxLayoutAlign="center start" style="height: 1px;">
    <mat-divider fxFlex="95"></mat-divider>
  </div>
  <div style="height: 5px;"></div>
  <div fxLayoutAlign="center start">
    <div fxFlex="10px"></div>
    <mat-form-field appearance="standard" fxFlex fxLayoutAlign="center center">
      <mat-label>Choisir une campagne</mat-label>
      <mat-select (selectionChange)="setCampaign(selected)" [(ngModel)]="selected">
        <mat-option *ngFor="let campaign of campaigns" [value]="campaign">
          {{ campaign.code.toUpperCase() }} {{ campaign.start_date | date:'YYYY' }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div fxFlex="10px"></div>
  </div>
  <div style="height: 5px;"></div>
  <div *ngIf="campaigns.length > 0" fxLayoutAlign="center start" style="height: 2px;">
    <mat-divider fxFlex="95"></mat-divider>
  </div>
  <div style="height: 10px;"></div>
  <div fxLayoutAlign="center start">
    <div class="sidebar-link" fxFlex="70" fxFlex.lt-lg="90" fxLayoutAlign="center center"
         routerLink="/dashboard/home"
         routerLinkActive="active-link">
      <div fxLayoutAlign="start center" fxFlex="90">
        <mat-icon>dashboard</mat-icon>
        <div fxFlex="5px"></div>
        <div class="font-16px">
          Tableau de bord
        </div>
      </div>
    </div>
  </div>
  <div style="height: 15px;"></div>
  <div fxLayoutAlign="center start">
    <div class="sidebar-link" fxFlex="70" fxFlex.lt-lg="90" fxLayoutAlign="center center"
         routerLink="/dashboard/files"
         routerLinkActive="active-link">
      <div fxLayoutAlign="start center" fxFlex="90">
        <mat-icon>folder</mat-icon>
        <div fxFlex="5px"></div>
        <div class="font-16px">
          Documents
        </div>
      </div>
    </div>
  </div>
  <div style="height: 15px;"></div>
</div>
