import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UserModel} from "../../models/user.model";
import {TokenModel} from "../../models/token.model";
import {Router} from '@angular/router';
import {CampaignService} from "../../services/campaign.service";
import {CampaignModel} from "../../models/campaign/campaign.model";
import * as _ from "lodash";

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardHeaderComponent implements OnInit {

  user: UserModel;
  campaigns: CampaignModel[] = null;
  isCampaignActive = {};

  constructor(private router: Router, private campaignSv: CampaignService) {
  }

  ngOnInit(): void {
    this.user = UserModel.getCurrent();
    this.campaignSv.clientCampaigns.subscribe((campaigns: CampaignModel[]) => {
      if (campaigns.length > 0) {
        this.campaigns = campaigns;
        this.campaigns.forEach((campaign) => {
          this.isCampaignActive[campaign.id] = false;
        });
        this.setActive(this.campaigns[0]);
      }
    });
  }

  logout() {
    TokenModel.removeCurrent();
    UserModel.removeCurrent();
    this.campaignSv.currentCampaign.next(null);
    this.campaignSv.clientCampaigns.next([]);
    this.router.navigate(['signin']);
  }

  setCampaign(campaign: CampaignModel) {
    this.isCampaignActive = _.mapValues(this.isCampaignActive, () => false);
    this.campaignSv.currentCampaign.next(campaign);
    this.setActive(campaign);
  }

  setActive(campaign: CampaignModel) {
    this.isCampaignActive[campaign.id] = true;
  }

}
