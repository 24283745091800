import {Component, OnInit} from '@angular/core';
import {UserModel} from "../../models/user.model";
import {TokenModel} from "../../models/token.model";
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {

  user: UserModel;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.user = UserModel.getCurrent();
  }


  logout() {
    TokenModel.removeCurrent();
    UserModel.removeCurrent();
    this.router.navigate(['signin']);
  }
}
