import {Injectable} from '@angular/core';
import {AuthService} from "../auth.service";
import {map, Observable} from "rxjs";
import {CampaignModel} from 'src/app/models/campaign/campaign.model';
import {StatisticModel} from 'src/app/models/campaign/statistic.model';

@Injectable({
  providedIn: 'root'
})
export class AdminCampaignService {

  constructor(private authSv: AuthService) {
  }

  fetchCampaign(campaignId: number): Observable<CampaignModel> {

    const url = '/campaign/' + campaignId;

    return this.authSv.get(url).pipe(map((response) => {

      return CampaignModel.generateModel(response.data);

    }));

  }

  fetchAllCampaigns(): Observable<CampaignModel[]> {

    const url = '/campaign/get_all';

    return this.authSv.get(url).pipe(map((response) => {

      return CampaignModel.generateModels(response.data);

    }));

  }

  fetchUnassignedCampaigns(): Observable<CampaignModel[]> {

    const url = '/campaign/get_unattributed';

    return this.authSv.get(url).pipe(map((response) => {

      return CampaignModel.generateModels(response.data);

    }));

  }

  fetchStatisticsForCampaign(campaignId: number): Observable<StatisticModel[]> {

    const url = '/statistic/' + campaignId;

    return this.authSv.get(url).pipe(map((response) => {

      return StatisticModel.generateModels(response.data);

    }))

  }


  updateCampaign(campaign: CampaignModel): Observable<any> {

    const url = '/campaign';

    campaign['action'] = 'edit';
    campaign['campaign_code'] = campaign.code;
    campaign['campaign_id'] = campaign.id;

    return this.authSv.patch(url, campaign, true).pipe(map((response) => {

      return response;

    }));
  }

  updateCampaigns(): Observable<any> {

    const url = '/campaign';
    const body = {'action': 'update_campaigns'};

    return this.authSv.post(url, body, true).pipe(map((response) => {

      return response;

    }));

  }

  updateCampaignStatistics(campaignId: number): Observable<any> {

    const url = '/statistic';
    let body = {};
    body['action'] = 'update_campaign_all_stats';
    body['campaign_id'] = campaignId;

    return this.authSv.post(url, body, true).pipe(map((response) => {

      return response;

    }));

  }

  updateCampaignsStatisticsLastWeek(): Observable<any> {

    const url = '/statistic';
    const body = {'action': 'update_all_stats_unfinished_last_week'};

    return this.authSv.post(url, body, true).pipe(map((response) => {

      return response;

    }));

  }

}
