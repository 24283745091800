import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from "@angular/router";
import {UserModel} from "../models/user.model";
import {TokenModel} from "../models/token.model";

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate {

  constructor(private router: Router) {
  }

  /**
   * Check if user has appropriate roles for the current route.
   * @param {ActivatedRouteSnapshot} route
   */
  canActivate(route: ActivatedRouteSnapshot) {
    const routeAllowedRole = route.data['routeAllowedRole'];

    if (UserModel.getCurrent() && TokenModel.getCurrent().token) {
      const user = UserModel.getCurrent();
      if (routeAllowedRole === user.role) {
        return true;
      }
    }

    return this.router.navigate(['signin']);

  }

}
