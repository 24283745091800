import {Component, Inject, OnInit} from '@angular/core';
import {ErrorModel} from "../../models/error.model";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent implements OnInit {

  successName = 'Succès';
  successMessage = 'L\'action à bien été réalisée';

  constructor(@Inject(MAT_DIALOG_DATA) public data: ErrorModel) {
  }

  ngOnInit(): void {
    if (this.data.name) {
      this.successName = this.data.name;
    }
    if (this.data.msg) {
      this.successMessage = this.data.msg;
    }
  }
}
