<div style="height: 100%; overflow: scroll" fxLayoutAlign="center start" class="scroll-bar-orange-thumb">
  <div fxFlex="98">
    <div style="height:10px"></div>
    <div class="box" style="height: 50px;" fxLayoutAlign="center center">
      <div fxFlex="10px"></div>
      <div fxLayoutAlign="start center" fxFlex="140px">
        <button fxFlex="140px" mat-raised-button color="accent" style="height: 40px;"
                (click)="createClient()" [disabled]="isLoading">
          Créer
        </button>
      </div>
      <div fxFlex="10px"></div>
      <div fxLayoutAlign="center center" fxFlex>
        <p class="font-20px no-margin text-overflow-ellipsis semi-bold">
          Clients ({{ dataSource.data.length }})
        </p>
      </div>
      <div fxFlex="160px" fxHide.lt-md="true"></div>
    </div>
    <div style="height:20px"></div>
    <div fxLayoutAlign="start center">
      <div fxFlex="100">
        <mat-form-field appearance="standard" style="width: 100%;">
          <mat-label>Recherche</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Martin" #input>
        </mat-form-field>
        <div class="box no-scroll-bar" style="overflow: scroll">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table-hover pollster-table">

            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef> Identifiant</th>
              <td mat-cell class="font-16px"
                  *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef class="light-black-bg-color white-text font-14px"> Courriel</th>
              <td mat-cell class="font-16px"
                  *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <!-- Society Column -->
            <ng-container matColumnDef="society">
              <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef> Société
              </th>
              <td mat-cell class="font-16px"
                  *matCellDef="let element"> {{element.society}} </td>
            </ng-container>

            <!-- Address Column -->
            <ng-container matColumnDef="address">
              <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef> Adresse</th>
              <td mat-cell class="font-16px"
                  *matCellDef="let element"> {{element.address}} </td>
            </ng-container>

            <!-- Campaigns Column -->
            <ng-container matColumnDef="campaigns">
              <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef> Nombre de
                campagnes
              </th>
              <td mat-cell class="font-16px"
                  *matCellDef=" let element"> {{element.campaigns.length}} </td>
            </ng-container>

            <!-- Alternative email Column -->
            <ng-container matColumnDef="alternative_email">
              <th mat-header-cell class="light-black-bg-color white-text font-14px" *matHeaderCellDef>
                Couriels de contact
              </th>
              <td mat-cell class="font-16px" *matCellDef="let element">
                  <span *ngFor="let alt_email of element.alternative_email">
                    {{alt_email}}<br/>
                  </span></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" (click)="onClientClicked(element)"></tr>

            <!-- Row shown when there is no matching data. -->
            <ng-container *ngIf="!isLoading">
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6" style="border: 0 solid;">
                  Aucune donnée ne correspond à la recherche "{{input.value}}"
                </td>
              </tr>
            </ng-container>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                         showFirstLastButtons
                         aria-label="Select page of periodic elements">
          </mat-paginator>
          <div *ngIf="isLoading"
               style="display: flex; justify-content: center; align-items: center;">
            <mat-progress-spinner
              color="primary"
              mode="indeterminate">
            </mat-progress-spinner>
          </div>
        </div>
      </div>
    </div>
    <div style="height:20px"></div>
  </div>
</div>
