import {Injectable} from '@angular/core';
import {AuthService} from "./auth.service";
import {BehaviorSubject, map, Observable} from "rxjs";
import {CampaignModel} from '../models/campaign/campaign.model';
import {StatisticModel} from '../models/campaign/statistic.model';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  public currentCampaign: BehaviorSubject<CampaignModel> = new BehaviorSubject<CampaignModel>(null);
  public clientCampaigns: BehaviorSubject<CampaignModel[]> = new BehaviorSubject<CampaignModel[]>([]);

  constructor(private authSv: AuthService) {
  }

  fetchUserCampaigns(): Observable<CampaignModel[]> {

    const url = '/campaign?action=client_get';

    return this.authSv.get(url).pipe(map((response) => {

      return CampaignModel.generateModels(response.data);

    }));

  }

  fetchStatisticsByCampaign(campaignId: number): Observable<StatisticModel[]> {

    const url = '/statistic/' + campaignId;

    return this.authSv.get(url).pipe(map((response) => {

      return StatisticModel.generateModels(response.data);

    }));

  }

}
