import {Injectable} from '@angular/core';
import {AuthService} from "../auth.service";
import {map, Observable} from "rxjs";
import {ReportModel} from "../../models/campaign/report.model";

@Injectable({
  providedIn: 'root'
})
export class AdminReportService {

  constructor(private authSv: AuthService) {
  }

  fetchAllReports(): Observable<ReportModel[]> {

    const url = '/report?action=get_all';

    return this.authSv.get(url).pipe(map((response) => {

      return ReportModel.generateModels(response.data);

    }));

  }

  fetchReportsByCampaign(campaignId: number): Observable<ReportModel[]> {

    const url = '/report?action=get_by_campaign&campaign_id=' + campaignId;

    return this.authSv.get(url).pipe(map((response) => {

      return ReportModel.generateModels(response.data);

    }));
  }

  downloadReportById(reportId: number): Observable<any> {
    const url = '/report/download/' + reportId;

    return this.authSv.getPDF(url).pipe(map((response) => {

      return URL.createObjectURL(new Blob([response], {type: "application/pdf"}));

    }));
  }

  deleteReport(reportId: number): Observable<any> {
    const url = '/report/delete/' + reportId;

    return this.authSv.delete(url).pipe(map((response) => {

      return response;

    }));
  }

  addReport(formData: FormData): Observable<any> {
    const url = '/report';

    return this.authSv.postPDF(url, formData).pipe(map((response) => {

      return response;

    }))
  }
}
