<h1 mat-dialog-title fxLayoutAlign="center start">Confirmation</h1>
<div mat-dialog-content class="text-center font-16px">
  {{message}}
  <ng-container *ngIf="warningMessage">
    <br/>
    <span class="semi-bold">
    {{warningMessage}}
  </span>
  </ng-container>
</div>
<div style="height: 10px;"></div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-raised-button color="warn" (click)="cancel()">Annuler</button>
  <button mat-raised-button color="primary" (click)="confirm()">Valider</button>
</div>
<div style="height: 5px;"></div>
