<div style="height: 100%; overflow: scroll"
     *ngIf="!isLoading && client" fxLayoutAlign="center start" class="scroll-bar-orange-thumb">
  <div fxFlex="98">
    <div style="height:10px"></div>
    <div class="box" style="height: 50px;">
      <div fxFlex="10px"></div>
      <div fxLayoutAlign="start center" fxFlex="140px">
        <button fxFlex="140px" mat-raised-button color="accent" style="height: 40px;"
                (click)="campaignsOrClientClicked()">
          {{ !showCampaigns ? 'Campagnes' : 'Client' }}
        </button>
      </div>
      <div fxFlex="10px"></div>
      <div fxLayoutAlign="center center" fxFlex>
        <p class="font-20px no-margin semi-bold">
          Client numéro : {{ client.id }}
        </p>
      </div>
      <div fxFlex="160px" fxHide.lt-md="true"></div>
    </div>
    <div style="height:20px" ngStyle.lt-md="height: 10px;"></div>
    <div *ngIf="!showCampaigns">
      <div fxLayoutAlign="start center" fxLayoutAlign.xs="center start">
        <button fxFlex="200px" mat-raised-button style="height: 40px;" (click)="changePassword();" color="primary">
          Changer le mot de passe
        </button>
      </div>
      <div style="height:10px" fxHide.gt-sm="true"></div>
      <form #f="ngForm">
        <div fxLayoutAlign="end center" fxLayoutAlign.xs="center start">
          <button fxFlex="140px" mat-raised-button style="height: 40px;"
                  [disabled]="!f.valid || !f.dirty" (click)="saveClient(f); f.control.markAsPristine()" color="primary">
            Sauvegarder
          </button>
          <div fxFlex="20px"></div>
          <button fxFlex="140px" mat-raised-button style="height: 40px;"
                  [disabled]="!f.valid || !f.dirty" (click)="loadClient(); f.control.markAsPristine()"
                  color="warn">
            Annuler
          </button>
        </div>
        <div style="height:20px"></div>
        <div class="box">
          <div style="height:10px"></div>
          <div class="mat-form-field-no-bottom-space" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
            <div fxFlex="10px"></div>
            <mat-form-field appearance="standard" fxFlex ngStyle.xs="width: 95%;">
              <mat-label>Adresse électronique</mat-label>
              <input [disabled]="isFormDisabled" [(ngModel)]="client.email" matInput placeholder="email@email.com"
                     name="email"
                     [pattern]="regexEmail" type="email" required>
            </mat-form-field>
            <div fxFlex="20px" fxFlex.xs="10px"></div>
            <mat-form-field appearance="standard" fxFlex ngStyle.xs="width: 95%;">
              <mat-label>Société</mat-label>
              <input [disabled]="isFormDisabled" [(ngModel)]="client.society" matInput placeholder="Pollster"
                     name="society"
                     type="text" required>
            </mat-form-field>
            <div fxFlex="10px"></div>
          </div>
          <div style="height:10px"></div>
        </div>
        <div style="height:20px" ngStyle.lt-md="height: 10px;"></div>
        <div class="box">
          <div style="height:10px"></div>
          <div class="mat-form-field-no-bottom-space">
            <div fxFlex="10px"></div>
            <mat-form-field appearance="standard" fxFlex>
              <mat-label>Adresse postale</mat-label>
              <input [disabled]="isFormDisabled" [(ngModel)]="client.address" matInput placeholder="Adresse complète"
                     name="address" type="text"
                     required>
            </mat-form-field>
            <div fxFlex="10px"></div>
          </div>
          <div style="height:10px"></div>
        </div>
        <div style="height:20px" ngStyle.lt-md="height: 10px;"></div>
        <div>
          <div class="box">
            <div style="height:10px"></div>
            <div class="mat-form-field-no-bottom-space">
              <div fxFlex="10px"></div>
              <div fxLayout="row wrap" fxLayoutAlign="center start" ngModelGroup="alternative_email">
                <ng-container *ngFor="let altEmail of client.alternative_email; let i = index">
                  <mat-form-field appearance="standard" fxFlex fxFlex.gt-sm="350px">
                    <mat-label>Adresse électronique alternative</mat-label>
                    <input fxFlex [disabled]="isFormDisabled" [ngModel]="altEmail" matInput
                           placeholder="email@email.com"
                           name="{{i}}"
                           [pattern]="regexEmail" type="email" required>
                    <button fxFlex="30px" mat-mini-fab fxLayoutAlign="center center" style="height: 30px;"
                            color="warn" [disabled]="isFormDisabled"
                            (click)="onRemoveAlternativeEmail(altEmail); f.control.markAsDirty()">
                      <mat-icon>remove</mat-icon>
                    </button>
                  </mat-form-field>
                  <div fxFlex="20px" *ngIf="client.alternative_email.length !== i"></div>
                </ng-container>
              </div>
              <div fxFlex="10px"></div>
            </div>
            <div style="height:10px"></div>
          </div>
          <div style="height:10px"></div>
          <div class="mat-form-field-no-bottom-space" fxLayoutAlign="center center">
            <div fxFlex="10px"></div>
            <button mat-fab color="primary" *ngIf="!showAlternativeEmail" (click)="onShowAlternativeEmail()"
                    [disabled]="isFormDisabled">
              <mat-icon style="font-weight: bold;">add</mat-icon>
            </button>
            <button mat-fab color="primary" *ngIf="showAlternativeEmail" (click)="onShowAlternativeEmail()">
              <mat-icon style="font-weight: bold;">remove</mat-icon>
            </button>
            <div fxFlex="10px"></div>
          </div>
          <div style="height:10px"></div>
          <div class="box" *ngIf="showAlternativeEmail">
            <div style="height:10px"></div>
            <div class="mat-form-field-no-bottom-space" fxLayout="row wrap" fxLayoutAlign="center center">
              <div fxFlex="10px"></div>
              <mat-form-field appearance="standard" fxFlex fxFlex.gt-sm="350px">
                <mat-label>Adresse électronique alternative</mat-label>
                <input ngModel matInput placeholder="email@email.com" name="add_alt_email"
                       [pattern]="regexEmail" type="email" required>
              </mat-form-field>
              <div fxFlex="20px"></div>
              <button fxFlex="140px" mat-raised-button style="height: 40px;"
                      [disabled]="!f.valid" (click)="addAlternativeEmail(f)" color="primary">
                Ajouter
              </button>
              <div fxFlex="10px"></div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <app-admin-client-campaigns *ngIf="showCampaigns" [datasource]="client.campaigns" [client]="client">
    </app-admin-client-campaigns>
    <div style="height:10px"></div>
  </div>
</div>
<div *ngIf="isLoading"
     style="display: flex; justify-content: center; align-items: center; height: 100%;">
  <mat-progress-spinner
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
</div>
