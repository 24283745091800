<div class="no-scroll-bar" style="height: 100%; z-index: 10;" fxHide.xs="true" fxFlex="240px" fxFlex.lt-lg="180px">
  <div style="height: 15px;"></div>
  <div fxLayoutAlign="center start">
    <div fxFlex="20px"></div>
    <img mat-card-image src="./assets/pollster-big.png"
         alt="Logo Pollster"
         style="width: 100%; height: 85px; object-fit: contain;">
    <div fxFlex="20px"></div>
  </div>
  <div style="height: 60px;"></div>
  <div fxLayoutAlign="center start">
    <div class="sidebar-link" fxFlex="70" fxFlex.lt-lg="90" fxLayoutAlign="center center"
         routerLink="/admin/clients"
         routerLinkActive="active-link">
      <div fxLayoutAlign="start center" fxFlex="80">
        <mat-icon>people</mat-icon>
        <div fxFlex="5px"></div>
        <div class="font-16px">
          Clients
        </div>
      </div>
    </div>
  </div>
  <div style="height: 20px;"></div>
  <div fxLayoutAlign="center start">
    <div class="sidebar-link" fxFlex="70" fxFlex.lt-lg="90" fxLayoutAlign="center center"
         routerLink="/admin/campaigns"
         routerLinkActive="active-link">
      <div fxLayoutAlign="start center" fxFlex="80">
        <mat-icon>data_usage</mat-icon>
        <div fxFlex="5px"></div>
        <div class="font-16px">
          Campagnes
        </div>
      </div>
    </div>
  </div>
</div>
