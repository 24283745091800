<h1 mat-dialog-title fxLayoutAlign="center start">{{successName}}</h1>
<form #f="ngForm">
  <div mat-dialog-content>
    <div fxFlex="98">
      <div>
        <mat-form-field appearance="standard" fxFlex ngStyle.xs="width: 95%;">
          <mat-label>Adresse électronique</mat-label>
          <input [(ngModel)]="client.email" [disabled]="isDisabled"
                 matInput placeholder="email@email.com" name="email" [pattern]="regexEmail" type="email" required>
        </mat-form-field>
      </div>
      <div style="height: 10px;"></div>
      <div>
        <mat-form-field appearance="standard" fxFlex ngStyle.xs="width: 95%;">
          <mat-label>Mot de passe</mat-label>
          <input [(ngModel)]="password" [disabled]="isDisabled"
                 matInput placeholder="Mot de passe" minlength="6" name="password" type="password" required>
          <mat-hint class="mat-error" *ngIf="stringLength(password) < 6 && stringLength(password) > 0">
            Mot de passe trop court
          </mat-hint>
        </mat-form-field>
      </div>
      <div style="height: 10px;"></div>
      <div>
        <mat-form-field appearance="standard" fxFlex ngStyle.xs="width: 95%;">
          <mat-label>Confirmer le mot de passe</mat-label>
          <input [(ngModel)]="confirmPassword" [disabled]="isDisabled"
                 matInput placeholder="Mot de passe" minlength="6" name="confirmPassword" type="password" required>
          <mat-hint class="mat-error" *ngIf="!isPasswordMatch(f) && stringLength(password) > 6">
            Les mots de passe ne correspondent pas
          </mat-hint>
        </mat-form-field>
      </div>
      <div style="height: 10px;"></div>
      <div>
        <mat-form-field appearance="standard" fxFlex ngStyle.xs="width: 95%;">
          <mat-label>Société</mat-label>
          <input [(ngModel)]="client.society" [disabled]="isDisabled"
                 matInput placeholder="Pollster" name="society" type="text" required>
        </mat-form-field>
      </div>
      <div style="height: 10px;"></div>
      <div>
        <mat-form-field appearance="standard" fxFlex>
          <mat-label>Adresse postale</mat-label>
          <input [(ngModel)]="client.address" [disabled]="isDisabled"
                 matInput placeholder="Adresse complète" name="address" type="text" required>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div style="height: 10px;"></div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button mat-raised-button color="accent" mat-dialog-close="true" [disabled]="isDisabled">Fermer</button>
    <button mat-raised-button [disabled]="!f.valid || !f.dirty || isDisabled"
            (click)="addClient(f); f.control.markAsPristine()"
            color="primary">
      Sauvegarder
    </button>
  </div>
  <div style="height: 5px;"></div>
</form>
