import {Component, Input, OnInit} from '@angular/core';
import {AdminReportService} from "../../../services/admin/admin-report.service";
import {ReportModel} from "../../../models/campaign/report.model";
import {CampaignModel} from 'src/app/models/campaign/campaign.model';
import {MatTableDataSource} from "@angular/material/table";
import * as _ from "lodash";
import {SuccessDialogComponent} from "../../../dialog/success/success-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {AddPdfDialogComponent} from "../../../dialog/add-pdf/add-pdf-dialog.component";

@Component({
  selector: 'app-admin-campaign-reports',
  templateUrl: './admin-campaign-reports.component.html',
  styleUrls: ['./admin-campaign-reports.component.scss']
})
export class AdminCampaignReportsComponent implements OnInit {

  dataSource = new MatTableDataSource<ReportModel>();
  reportsToDelete: ReportModel[] = [];
  displayedColumns: string[] = ['id', 'file', 'date', 'is_consulted', 'remove_report'];
  isLoading = false;
  isDataChanged = false;
  isDisabled = false;

  @Input() isFinished: boolean = false;
  @Input() campaign: CampaignModel = null;

  constructor(private adminRepSv: AdminReportService, private dialog: MatDialog) {
  }

  ngOnInit(): void {

    this.isLoading = true;
    if (this.campaign) {
      this.loadReports();
    }

  }

  loadReports() {

    this.isDisabled = true;
    this.adminRepSv.fetchReportsByCampaign(this.campaign.id).subscribe((reports: ReportModel[]) => {
      this.dataSource.data = reports;
      this.reportsToDelete = [];
      this.isDataChanged = false;
      this.isLoading = false;
      this.isDisabled = false;
    });

  }

  async deleteReports() {

    let count = 0;
    for (const report of this.reportsToDelete) {
      await new Promise<void>((resolve, reject) => {
        this.adminRepSv.deleteReport(report.id).subscribe(() => {
          count += 1;
          resolve();
        }, (err) => {
          reject(new Error(err));
        });
      }).catch((err) => {
        console.error(err);
      });
    }
    this.loadReports()

    this.dialog.open(SuccessDialogComponent, {
      minWidth: '20%',
      data: {
        msg: count + ' documents supprimés avec succès !'
      },
      autoFocus: false
    });
  }

  removeReport(report: ReportModel) {

    _.remove(this.dataSource.data, (r: ReportModel) => {
      return r.id === report.id;
    });

    this.reportsToDelete.push(report);
    this.isDataChanged = true;
    this.dataSource._updateChangeSubscription();

  }

  openPDF(report: ReportModel) {
    const windowReference = window.open();

    this.adminRepSv.downloadReportById(report.id).subscribe((pdfURL) => {

      windowReference.location = pdfURL;

    });

  }

  addReport() {
    this.dialog.open(AddPdfDialogComponent, {
      minWidth: '60%',
      data: {campaign: this.campaign},
      autoFocus: false
    }).afterClosed().subscribe(event => {
      if (event) {
        if (event.data) {
          this.loadReports();
        }
      }
    })
  }
}
