export class DateUtils {

  static date(string: string): Date {

    if (string !== undefined && string !== null) {

      return new Date(string);
    }

    return null;
  }

  static getTime(date?: any) {
    return date != null ? new Date(date).getTime() : 0;
  }

}
