<div
  style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.75); z-index: 100; height: 100%;">
  <div fxLayoutAlign="center center" style="height: 100%;">
    <div>
      <div fxLayoutAlign="center center">
        <mat-progress-spinner
          color="primary"
          mode="indeterminate">
        </mat-progress-spinner>
      </div>
      <div style="height: 20px;"></div>
      <div fxLayoutAlign="center center">
        <p class="font-20px no-margin white-text" style="line-height: 1.5;">
          <ng-content></ng-content>
        </p>
      </div>
    </div>
  </div>
</div>
