import {Injectable} from '@angular/core';
import {map, Observable} from "rxjs";
import {UserModel} from 'src/app/models/user.model';
import {AuthService} from "../auth.service";
import {CampaignModel} from "../../models/campaign/campaign.model";
import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class AdminClientService {

  constructor(private authSv: AuthService) {
  }

  fetchAllClients(): Observable<UserModel[]> {

    let url = '/client?action=get_all';

    return this.authSv.get(url).pipe(map((response) => {

      return UserModel.generateModels(response.data);

    }));

  }

  fetchClient(clientId: number): Observable<UserModel> {

    const url = '/client/' + clientId;

    return this.authSv.get(url).pipe(map((response) => {

      return UserModel.generateModel(response.data);

    }));
  }

  addClient(client: UserModel, password: string): Observable<any> {

    const url = '/client';
    let body = {};

    body['action'] = 'add';
    body['email'] = client.email;
    body['password'] = password;
    body['address'] = client.address;
    body['society'] = client.society;

    return this.authSv.post(url, body, true).pipe(map((response) => {

      return response;

    }));

  }

  updateClient(client: UserModel): Observable<any> {

    const url = '/client';

    client['action'] = 'edit';
    client['client_id'] = client.id;

    return this.authSv.post(url, client, true).pipe(map((response) => {

      return response;

    }));

  }

  updateClientCampaigns(campaigns: CampaignModel[], clientId: number): Observable<any> {

    const url = '/client';

    let body = {};
    body['action'] = 'set_campaign';
    body['client_id'] = clientId;
    body['campaigns'] = _.map(campaigns, 'id');

    return this.authSv.post(url, body, true).pipe(map((response) => {

      return response;

    }));

  }

  changePassword(password: string, clientId: number): Observable<any> {

    const url = '/client'

    let body = {};
    body['action'] = 'edit_password';
    body['client_id'] = clientId;
    body['password'] = password;

    return this.authSv.post(url, body, true).pipe(map((response) => {

      return response;

    }));

  }

}
